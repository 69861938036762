import { HomepageFeatureCode, HomepageIDModuleAPIService, CompanyRating } from 'services';

export class RatingsAPIService extends HomepageIDModuleAPIService {

  constructor() {
    super({
      module: HomepageFeatureCode.ratings,
    });
  }

  getAvg(): Promise<CompanyRating> {
    return this.callGet('getAvg', 'avg');
  }
}