import {CommonSuccessResponse, HomepageFeatureSettings, HomepageIDModuleAPIService, QueryParams} from 'services';

export class HomepageFeatureSettingsService extends HomepageIDModuleAPIService {

  public constructor() {
    super({
      module: 'features',
    });
  }

  async saveSelectedFeatureType(featureID: number, featureTypeID: number): Promise<CommonSuccessResponse> {
    return super.callPost('save', `save/${featureID}`, {featureTypeID: featureTypeID});
  }

  async getFeatureInfo(code: string, queryParams?: QueryParams): Promise<HomepageFeatureSettings> {
    return this.callGet('get', `get/${code}`, queryParams);
  }

  async saveActiveStatus(FID: number, active: boolean): Promise<CommonSuccessResponse> {
    return super.callPost('save', `save/${FID}`, {active: active});
  }
}