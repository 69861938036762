import * as React                       from 'react';
import { FunctionValidator, Validator } from 'utils/Validator';


interface ItemValidator {
  field: string | null // if null all fields are complied
  validator: RegExp | FunctionValidator;
}


export class FormValidator {

  private validators: ItemValidator[];

  constructor(validators?: ItemValidator | ItemValidator[]) {
    this.validators = validators ? (Array.isArray(validators) ? validators : [validators]) : [];
  }

  add(field: string, validator: RegExp | FunctionValidator) {
    if (this.exists(field)) {
      return;
    }
    this.validators.push({
      field    : field,
      validator: validator
    });
  }

  exists(field: string): boolean {
    return this.validators.some(item => item.field === field);
  }

  /**
   * Returns true if valid
   * @param field
   * @param value
   */
  isValid(field: string, value: any): boolean {
    const hasInValid = this.validators.some((item: ItemValidator) => {
      if (item.field === null || (item.field === field)) {
        if (!Validator.isValid(value, field, item.validator)) {
          return true;
        }
      }
      return false;
    });
    return !hasInValid;
  }
}

interface ValidatorHTMLElementPropsProps {
  name?: string;
  validator?: RegExp | FunctionValidator;
}

export interface ValidatorInputProps extends React.InputHTMLAttributes<HTMLInputElement>, ValidatorHTMLElementPropsProps {
  hasWarning?: boolean
}

export interface ValidatorTextAreaProps extends React.InputHTMLAttributes<HTMLTextAreaElement>, ValidatorHTMLElementPropsProps {
  hasWarning?: boolean;
}