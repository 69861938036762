import {Homepage, HomepageFeatureAPIService, HomepageFeatureCode, HomepageFeatureSettings, HomepageSaveFormOpt, KeyValueObject, QueryParams} from 'services';
import {ConfigService} from 'services/Config/ConfigService';
import {APICollection} from 'utils/Collection';
import {errorModal} from 'utils/errorModal';
import {APIFormCollection, APIFormCollectionProps} from 'utils/Form';
import {GlobalVars} from 'utils/GlobalVars';

interface HomepageFeatureFormProps extends APIFormCollectionProps {
  list: APICollection;
  api: HomepageFeatureAPIService;
  homepage: Homepage;
}

export class HomepageFeatureForm extends APIFormCollection {
  public relList: APICollection;
  public api!: HomepageFeatureAPIService;
  public homepage!: Homepage;

  constructor(props: HomepageFeatureFormProps) {
    super(props);
    this.relList = props.list;
    this.api = props.api;
    this.homepage = props.homepage;
  }

  isSavedOnly(): boolean {
    return this.getValue(0, 'savedOnly', null) === false;
  }

  hasPicturesInPayload(): boolean {
    return this.some(row => row.isNotEmpty('picture64File'));
  }


  savePublish(feature?: HomepageFeatureSettings, queryParams?: QueryParams): void {
    this.optSave(HomepageSaveFormOpt.savePublish, feature, queryParams);
  }

  optSave(opt: HomepageSaveFormOpt, feature?: HomepageFeatureSettings, queryParams?: QueryParams): void {
    if (feature?.code.toString() === HomepageFeatureCode.blog.toString()) {
      this.saveBLog();
    }
    else {
      this.doOptSave(opt, feature, queryParams);
    }
  }

  private saveBLog(): void {
    super.save().then((response) => {
      if (!response) {
        return response;
      }
      this.relList.load();
      return response;
    });
    return;
  }

  private doOptSave(opt: HomepageSaveFormOpt, feature?: HomepageFeatureSettings, queryParams?: QueryParams): void {
    if (this.homepage.usedSpaceGB > ConfigService.maxHomepageSizeInGB() && this.hasPicturesInPayload()) {
      errorModal('Lubatud maht on täis, ei sa pilte laadida');
      return;
    }

    if (this.isEmpty() && GlobalVars.isUserRoleCRM() && opt === HomepageSaveFormOpt.savePublishAll) {
      this.api.publishAll(feature?.selectedTypeID ?? 0).then(() => {
        this.relList.reMap(i => {
          i.publisehd = true;
          i.savedOnly = false;
          return i;
        });
        this.reset();
      });
      return;
    }
    queryParams = (opt === HomepageSaveFormOpt.savePublishAll ? {publishAll: true} : {}) as QueryParams;

    this.addSaveFormatter((row: KeyValueObject) => {
      if (!this.isEdit()) {
        row.published = true;
      }
      if (opt === HomepageSaveFormOpt.saveOnly && GlobalVars.isUserRoleCRM()) {
        row.savedOnly = true;
      }

      if (GlobalVars.isUserRoleCRM()) {
        row.featureTypeID = feature?.selectedTypeID ?? null;
      }

      return row;
    });

    super.saveMany(queryParams).then((response) => {
      if (!response) {
        return response;
      }
      this.relList.replace(response);
      // if (opt === HomepageSaveFormOpt.savePublishAll) {
      //   this.relList.replace(this.relList.filter(item => !item.savedOnly));
      // }
      // else {
      //   this.relList.replace(response);
      // }
      this.reset();
      return response;
    });
    return;
  }
}