import axios, {AxiosRequestConfig} from 'axios';
import {useEffect, useMemo, useState} from 'react';
import {Token, TokenService} from "../services";

export const API_URL = process.env.REACT_APP_API_URL;
export const axiosInstance = axios.create({
    // withCredentials : true,
    baseURL: API_URL,
});

axiosInstance.interceptors.request.use(
    async (config: AxiosRequestConfig) => {
        if (config.url?.substring(0, 6) !== '/token') {
            return await (new TokenService()).get().then(token => {
                if (!config.headers) {
                    config.headers = {};
                }
                config.headers.Authorization = `Bearer ${token.jwt.replace('"', '')}`;
                return config;
            });
        }
        return config;
    },
    error => error
);

let refreshTokenPromise: Promise<any>|null; // this holds any in-progress token refresh requests

axiosInstance.interceptors.response.use(r => r, error => {
    if (error.config && error.response && error.response.status === 401) {
        if (!refreshTokenPromise) { // check for an existing in-progress request
            // if nothing is in-progress, start a new refresh token request
            refreshTokenPromise = (new TokenService()).reCreate().then(token => {
                TokenService.store(token);
                refreshTokenPromise = null; // clear state
                return token; // resolve with the new token
            });
        }

        return refreshTokenPromise.then((token: Token) => {
            error.config.headers.Authorization = `Bearer ${token.jwt.replace('"', '')}`;
            return axiosInstance.request(error.config);
        });
    }
    return Promise.reject(error);
});

export const useAxiosLoader = () => {
    const [counter, setCounter] = useState(0);
    const [progress, setProgress] = useState(0);

    const interceptors = useMemo(() => {
        const inc = () => {
            setCounter(counter => counter + 1);
        };
        const dec = () => {
            //console.log('dec');
            setCounter(counter => counter - 1);
        };

        return ({
            request: (config: any) => {
                inc();
                config.onUploadProgress = (progressEvent: ProgressEvent) => {
                    let progress = 0;
                    if (config.progressCalculator) {
                        progress = config.progressCalculator(progressEvent);
                    }
                    else {
                        progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    }
                    setProgress(progress);
                };
                return config;
            },
            response: (response: any) => {
                dec();
                return response;
            },
            error: (error: any) => {
                dec();
                return Promise.reject(error);
            },
        });
    }, []); // create the interceptors

    useEffect(() => {
        // add request interceptors
        const reqInterceptor = axiosInstance.interceptors.request.use(interceptors.request, interceptors.error);
        // add response interceptors
        const resInterceptor = axiosInstance.interceptors.response.use(interceptors.response, interceptors.error);
        return () => {
            // remove all intercepts when done
            axiosInstance.interceptors.request.eject(reqInterceptor);
            axiosInstance.interceptors.response.eject(resInterceptor);
        };
    }, [interceptors]);

    return [counter > 0, progress];
};
