import {APIService, ChangePasswordRequest, CommonSuccessResponse, CompanyResponse, CreateLoginRequest, RestorePasswordRequest, SSCheckCompanyResponse} from 'services';

export class SelfServiceService extends APIService {

    constructor() {
        super({
            basePath: '/ss'
        });
    }

    login(email: string, password: string): Promise<CommonSuccessResponse> {
        return this.callPost('login', 'login', {email: email, password: password});
    }

    checkCompany(hash: string): Promise<SSCheckCompanyResponse> {
        return this.callGet('check', `check/${hash}`);
    }

    createLogin(loginInfo: CreateLoginRequest): Promise<SSCheckCompanyResponse> {
        return this.callPost('createLogin', 'createLogin', loginInfo);
    }

    changePassword(data: ChangePasswordRequest): Promise<CommonSuccessResponse> {
        return this.callPost('changePassword', 'changePassword', data);
    }

    forgotPassword(email: string): Promise<CommonSuccessResponse> {
        return this.callPost('forgotPassword', 'forgotPassword', {email});
    }

    restorePassword(restoreInfo: RestorePasswordRequest): Promise<CommonSuccessResponse> {
        return this.callPost('restorePassword', 'createNewPassword', restoreInfo);
    }

    getCompany(): Promise<CompanyResponse> {
        return this.callGet('company', 'company');
    }
}