import {KeyValueObject} from 'services';
import {collectFlatten} from 'utils/arrayExtendors';

interface ObjectFormatterCallback {
  (row: KeyValueObject): KeyValueObject;
}

export type ObjectFormatterPropsType = ObjectFormatterCallback | ObjectFormatterCallback[];

export class ObjectFormatter {
  protected formatters: ObjectFormatterCallback[] = [];
  
  constructor(formatters?: ObjectFormatterPropsType, public name = '') {
    if (formatters) {
      this.add(formatters);
    }
  }
  
  add(formatter?: ObjectFormatterPropsType): this {
    if (formatter) {
      this.formatters.push(...(collectFlatten(formatter) as ObjectFormatterCallback[]));
    }
    return this;
  }
  
  rows<T extends KeyValueObject>(rows: T[]): T[] {
    return rows.map(row => this.fire(row));
  }
  
  
  fire<T extends KeyValueObject>(row: T): T {
    let newRow = {...row} as KeyValueObject;
    this.formatters.filter(f => f !== undefined).forEach((formatter: ObjectFormatterCallback) => {
      newRow = formatter(newRow);
    });
    return newRow as T;
  }
}

