import * as React                          from 'react';
import LoadingOverlay                      from 'shared/components/LoadingOverlay';
import { useAxiosLoader } from 'utils/api';

const APIVisualInterceptor: React.FC = () => {
  const [loadingCounter, progress]   = useAxiosLoader();
  const isLoading = loadingCounter > 0;
  const realProgress              = (isLoading ? progress : 0) as number;
  return (
    <LoadingOverlay state={isLoading} progress={realProgress}/>
  );
};

export default APIVisualInterceptor;
