import {APIService, CompanyInvoicesResponse, CompanyService, EmblemsResponse, GeneralStatisticsResponse, CommonSuccessResponse, GraphHighChartsResponse, GraphLabelsResponse, OrderCompanyServiceRequest, QueryParams, TimePeriodsResponse} from 'services';

export class SelfServiceCompanyService extends APIService {

  constructor() {
    super({
      basePath: '/ss/company'
    });
  }

  getInvoices(queryParams?: QueryParams): Promise<CompanyInvoicesResponse[]> {
    return super.callGet('getInvoices', 'invoices', queryParams);
  }

  getUnPayedInvoices(queryParams?: QueryParams): Promise<CompanyInvoicesResponse[]> {
    return super.callPost('getInvoices', 'invoices', {
      filters: {
        isPayed: false
      }
    }, queryParams);
  }

  getServices(queryParams?: QueryParams): Promise<CompanyService[]> {
    return super.callGet('getInvoices', 'services', queryParams);
  }

  orderService(data: OrderCompanyServiceRequest, queryParams?: QueryParams): Promise<CommonSuccessResponse> {
    return super.callPost('orderService', 'service/order', data, queryParams);
  }

  getEmblems(queryParams?: QueryParams): Promise<EmblemsResponse[]> {
    return super.callGet('getEmblems', 'emblems', queryParams);
  }

  getStatisticTimePeriods(queryParams?: QueryParams): Promise<TimePeriodsResponse[]> {
    return super.callGet('getStatisticTimePeriods', 'statistics/timePeriods', queryParams);
  }

  getGeneralStatistic(type: string, timePeriods: { fromDate: string, toDate: string }, queryParams?: QueryParams): Promise<GeneralStatisticsResponse[]> {
    return super.callPost('getGeneralStatistic', `statistics/general/${type}`, timePeriods, queryParams);
  }

  getStatisticGraphLabels(queryParams?: QueryParams): Promise<GraphLabelsResponse[]> {
    return super.callGet('getStatisticGraphLabels', 'statistics/graph/labels', queryParams);
  }

  getStatisticGraphHighCharts(code: string, timePeriods: { fromDate: string, toDate: string }, queryParams?: QueryParams): Promise<GraphHighChartsResponse> {
    return super.callPost('getStatisticGraphHighCharts', `statistics/graph/highcharts/${code}`, timePeriods, queryParams);
  }
}