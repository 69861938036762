import * as React             from 'react';
import { commonLocalization } from 'shared/commonLocalization';
import spinner                from 'shared/components/LoadingOverlay/loadingGif.gif';
import Modal                  from 'shared/components/Modal';
import * as Styled            from './LoadingOverlay.styled';

interface OwnProps {
  progress?: number;
  state: boolean
  title?: string | null;
}

const LoadingOverlay: React.FC<OwnProps> = (props) => {
  const onClose = () => {
    console.log('close');
  };

  const title = props.title ?? commonLocalization.loadingOverlayTitle;

  return (
    <Modal
      isOpen={props.state}
      onClose={onClose}
      shouldCloseOnOverlayClick={false}
      overlayCSS={{
        zIndex: 999999
      }}
      contentCSS={{
        position : 'fixed',
        top      : '50%',
        left     : '50%',
        transform: 'translate(-50%, -50%)',
        margin   : 'auto'
      }}
    >
      <Styled.LoadingWrapper>
        <Styled.LoadingTitle>{title}</Styled.LoadingTitle>
        <Styled.LoadingSpinner>
          <img src={spinner} alt={'spinner'}/>
        </Styled.LoadingSpinner>
        {props.progress ? <Styled.LoadingStatus>{props.progress}%</Styled.LoadingStatus> : null}
      </Styled.LoadingWrapper>
    </Modal>
  );
};

export default LoadingOverlay;
