export class ConfigService {

  static isLocal(): boolean {
    return ConfigService.isLocalSelfService() || ConfigService.isLocalCRM();
  }

  static maxHomepageSizeInGB(): number {
    return 5;
  }

  static getPreviewURL(): string {
    if (ConfigService.isLocal()) {
      return 'https://hcw.hange.loc/';
    }
    if (ConfigService.isDev()) {
      return 'https://dev.eelvaade.hange.ee/';
    }
    return 'https://eelvaade.hange.ee/';
  }

  static isLocalSelfService(): boolean {
    return window.location.host === 'localhost:3000';
  }

  static isLocalCRM(): boolean {
    return window.location.host === 'localhost:3001';
  }

  static isDev(): boolean {
    return ConfigService.isDevCRM() || ConfigService.isDEVSelfService() || ConfigService.isLocal();
  }

  static isProduction(): boolean {
    return ConfigService.isProductionCRM() || ConfigService.isProductionSelfService();
  }

  static isCRM(): boolean {
    return ConfigService.isDevCRM() || ConfigService.isProductionCRM() || ConfigService.isLocalCRM();
  }

  static isSelfService(): boolean {
    return ConfigService.isProductionSelfService() || ConfigService.isDEVSelfService() || ConfigService.isLocalSelfService();
  }

  static isProductionSelfService(): boolean {
    return window.location.host === 'iseteenindus.hange.ee';
  }

  static isDEVSelfService(): boolean {
    return ['ss.hange.ee', 'dev.iseteenindus.hange.ee'].includes(window.location.host);
  }

  static isProductionCRM(): boolean {
    return window.location.host === 'crm2.hange.ee';
  }

  static isDevCRM(): boolean {
    return window.location.host === 'dev.crm2.hange.ee';
  }

  static getCRMLoginUrl(): string {
    if (ConfigService.isLocal()) {
      return 'https://crm.hange.loc/login';
    }
    if (ConfigService.isDev()) {
      return 'https://dev.crm2.hange.ee/login';
    }
    return 'https://crm.hange.ee/login';
  }
}