import {AxiosRequestConfig, AxiosResponse} from 'axios';
import {axiosInstance} from 'utils/api';

export class AxiosAPIDriver {

    async call(method: string, uri: string, config?: AxiosRequestConfig, payload?: any): Promise<any> {

        let promise: Promise<any>;
        if (['get', 'delete', 'head', 'options'].includes(method)) {
            // @ts-ignore
            promise = axiosInstance[method](uri, config);
        }
        else {
            // @ts-ignore
            promise = axiosInstance[method](uri, payload, config);
        }

        return promise.then((response: AxiosResponse<any>) => response.data);
    }
}
