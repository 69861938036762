import {CommonSuccessResponse, KeyValueObject, QueryParams} from 'services';
import {APIActionServiceContract} from 'services/api/APICollection_FormContracts';
import {CollectionStateManager, CollectionStateManagerProps} from 'utils/Collection';
import {FunctionEventCallback, FunctionEventTypeEnum} from 'utils/FunctionEvents';
import {ObjectFormatter, ObjectFormatterPropsType} from 'utils/ObjectFormatter';

export interface APICollectionProps extends CollectionStateManagerProps {
  api?: APIActionServiceContract;
  loadFormatter?: ObjectFormatterPropsType;

  //events
  afterLoad?: FunctionEventCallback;
}


export class APICollection extends CollectionStateManager {

  public api!: APIActionServiceContract;
  private loadFormatter = new ObjectFormatter();

  constructor(props: APICollectionProps) {
    super(props);
    if (props.api) {
      this.api = props.api;
    }
    this.loadFormatter.add(props.loadFormatter);
    this.afterOrder(ids => {
      this.reOrder(ids);
    });
  }


  reOrder(ids: number[]): Promise<CommonSuccessResponse> {
    if (!this.api) {
      throw new Error('API is undefined');
    }
    if (!this.api.reOrder) {
      throw new Error('delete API method undefined');
    }
    return this.api.reOrder(ids);
  }

  load(queryParams?: QueryParams): Promise<KeyValueObject[]> {
    if (!this.api) {
      throw new Error('API is undefined');
    }
    return this.api.getList(queryParams).then(response => {
      this.setItems(this.loadFormatter.rows(response));
      this.updateState(response);
      this.events.fire(FunctionEventTypeEnum.afterLoad, response);
      return response;
    });
  }

  delete(ID: number, queryParams?: QueryParams): Promise<CommonSuccessResponse> {
    if (!this.api) {
      throw new Error('API is undefined');
    }
    this.removeByID(ID);
    if (!this.api.delete) {
      throw new Error('delete API method undefined');
    }
    return this.api.delete(ID, queryParams);
  }

  afterLoad(caller: FunctionEventCallback): void {
    this.events.add(FunctionEventTypeEnum.afterLoad, caller);
  }

  //region HTML events
  deleteRowClick(ID: number): () => void {
    if (!this.api) {
      throw new Error('API is undefined');
    }
    return () => {
      if (!this.api.delete) {
        throw new Error('delete API method undefined');
      }
      return this.delete(ID).then((res) => {
        this.removeByID(ID);
        return res;
      });
    };
  }

  toggleVisibilityClick(ID: number, bol: boolean): () => void {
    if (!this.api) {
      throw new Error('API is undefined');
    }
    return () => {
      this.api.togglePublish(ID, bol).then(() => this.changeValueByID(ID, 'published', bol));
    };
  }

  //endregion

}
