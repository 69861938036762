const mediaQueries = {
  extraSmall: '(max-width: 320px)',
  mobile: '(max-width: 767px)',
  mobileOrTablet: '(max-width: 1024px)',
  tablet: '(min-width: 768px) and (max-width: 1024px)',
  tabletPortrait: '(min-width: 768px) and (max-width: 1024px) and (orientation: portrait)',
  tabletLandscape: '(min-width: 768px) and (max-width: 1024px) and (orientation: landscape)',
  tabletOrDesktop: '(min-width: 768px)',
  desktop: '(min-width: 1025px)',
};

export default mediaQueries;
