import { connectRouter }   from 'connected-react-router';
import { History }         from 'history';
import { combineReducers } from 'redux';
import { gsm }             from 'store/globalStateManager';

const rootReducer = (history: History) =>
  combineReducers({
    router  : connectRouter(history),
    pageInfo: gsm.reducer
  });

export default rootReducer;
