import {HomepageFeatureAPIServiceProps, HomepageFeatureCode, HomepageFeatureAPIService, SelectValuesResponse} from 'services';
import StateManager, {SelectValuesState} from 'utils/StateManager';

interface OwnProps extends HomepageFeatureAPIServiceProps {
  categoriesState: SelectValuesState;
  unitsState: SelectValuesState;
}

export class PriceListService extends HomepageFeatureAPIService {
  public Categories: StateManager;
  public Units: StateManager;

  public constructor(props: OwnProps) {
    super(Object.assign(props, {
      module: HomepageFeatureCode.priceList
    }));
    this.Categories = new StateManager(props.categoriesState);
    this.Units = new StateManager(props.unitsState);
  }
}