import { Dispatch }                       from 'redux';
import { createSelector, OutputSelector } from 'reselect';
import { commonLocalization }             from 'shared/commonLocalization';
import { RootState }                      from '../index';

export enum PageStoreActions {
  pageInfo    = 'pageInfo',
  error       = 'error',
}

export interface ErrorState {
  state: boolean;
  title: string;
  content: string;
}


const DEFAULT_STATE = {
  error      : {
    state  : false,
    title  : '',
    content: ''
  } as ErrorState
};

interface SelectorsInterface {
  [key: string]: OutputSelector<any, any, any>;
}

class GlobalStateManager {
  protected selectors: SelectorsInterface;

  constructor() {
    this.selectors = {} as SelectorsInterface;
  }

  dispatch!: Dispatch;
  useSelector!: (selector: (state: any) => any, equalityFn?: (left: any, right: any) => boolean) => any;

  withDispatch(dispatcher: Dispatch): this {
    this.dispatch = dispatcher;
    return this;
  }

  withSelector(selector: (selector: (state: any) => any, equalityFn?: (left: any, right: any) => boolean) => any): this {
    this.useSelector = selector;
    return this;
  }

  reducer(state = DEFAULT_STATE, action: any) {
    switch (action.type) {
      case PageStoreActions.error: {
        return {
          ...state,
          error: action.payload,
        };
      }
      default:
        return state;
    }
  }

  createSelectors() {
    const localState                             = (state: RootState) => state.pageInfo;
    this.selectors[PageStoreActions.error]       = createSelector(localState, state => state.error);
  }

  private dispatchAction(action: string, payload: any) {
    this.dispatch && this.dispatch({
      type   : action,
      payload: payload,
    });
    return payload;
  }

  private select(selector: string, defaults: any = null): any | null {
    if (this.selectors[selector]) {
      return this.useSelector(this.selectors[selector]);
    }
    return defaults;
  }

  getError(): ErrorState {
    return this.select(PageStoreActions.error);
  }

  error(error?: any, title?: string) {
    let errorContent = '';
    let errorTitle   = commonLocalization.errorTitle;

    if (typeof error === 'string' && typeof title === 'string') {
      errorContent = error;
      errorTitle   = title;
    }
    else if (typeof error === 'string') {
      errorContent = error;
    }
    else if (error.response?.status === 500) {
      errorContent = commonLocalization.serverError;
    }
    else if (error.response.data) {
      const data = error.response.data;
      if (data.modal) {
        errorContent = data.modal.content;
        errorTitle   = data.modal.title;
      }
      else if (data.error && !data.message) {
        errorContent = data.error;
      }
      else if (!data.error && data.message) {
        errorContent = data.message;
      }
      else {
        errorContent = data.message;
        errorTitle   = data.error;
      }
    }
    this.dispatchAction(PageStoreActions.error, {
      state  : true,
      title  : errorTitle,
      content: errorContent
    });
  }

  closeError() {
    this.dispatchAction(PageStoreActions.error, {
      state  : false,
      title  : '',
      content: ''
    });
  }
}

export const gsm = new GlobalStateManager();


