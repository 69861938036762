import {Active, Over} from '@dnd-kit/core/dist/store';
import {DragEndEvent} from '@dnd-kit/core/dist/types';
import {arrayMove} from '@dnd-kit/sortable';
import {toNumber} from 'lodash';
import * as React from 'react';
import {ImageType} from 'react-images-uploading/dist/typings';
import {KeyValueObject, KeyValueValueObject} from 'services';
import {OptionItem} from 'shared/components/Select';
import {CollectionItem} from 'utils/Collection';
import {FunctionEventCallback, FunctionEvents, FunctionEventType, FunctionEventTypeEnum} from 'utils/FunctionEvents';
import {ObjectFormatter, ObjectFormatterPropsType} from 'utils/ObjectFormatter';

interface EmptyEventCallback {
  (): any;
}

interface AfterOrderEventCallback {
  (ids: number[]): any;
}

interface AfterChangeValueCallBack {
  (value: any, field: string, index: number, row: KeyValueObject, list: Collection): any;
}

export interface CollectionOptions {
  rowFormatter?: ObjectFormatterPropsType; //used just before row is added/set

  //events
  afterEdit?: EmptyEventCallback;
  afterChangeValue?: AfterChangeValueCallBack;
  afterDelete?: FunctionEventCallback;
  afterOrder?: AfterOrderEventCallback;
}

export interface CollectionProps extends CollectionOptions {
  items: KeyValueObject[];
}

export interface KeyValueObjectFinderCallback {
  (row: KeyValueObject, index?: number): boolean;
}

type anyNull = any | null;


export class Collection {
  protected itemFormatter = new ObjectFormatter();
  protected events: FunctionEvents;
  private items: CollectionItem[];
  private isItemsDirty: boolean;

  constructor(props: CollectionProps) {
    this.items = [...props.items].map(item => new CollectionItem(item));
    this.itemFormatter.add(props.rowFormatter);
    this.events = new FunctionEvents(props);
    this.isItemsDirty = false;
  }


  haveAny(): boolean {
    return this.items.length > 0;
  }

  empty(): boolean {
    return this.items.length <= 0;
  }

  exists(index: number, field?: string): boolean {
    if (field !== undefined) {
      return this.at(index).exists(field);
    }
    else {
      return !!this.items[index];
    }
  }

  isDirty(): boolean {
    return this.isItemsDirty;
  }

  //region setters

  /**
   * Similar to replace but without events
   * @param rows
   */
  setItems(rows: KeyValueObject[]): this {
    this.items = [...rows].map((item) => new CollectionItem(item));
    return this;
  }


  replace(rows: KeyValueObject[]): this {
    this.setItems(rows);
    this.fireEvent();
    return this;
  }

  changeValue(index: number, field: string, value: any): this {
    if (!this.exists(index)) {
      throw new Error("index('" + field + "') does not exist");
    }
    this.isItemsDirty = this.at(index).set(field, value).isDirty();
    this.fireEvent(FunctionEventTypeEnum.afterChangeValue, {value: value, field: field, index: index, row: this.at(index).getItem(), list: this});
    return this;
  }

  changeValueByID(ID: number, field: string, value: any): this {
    const index = this.getIndexByID(ID);
    if (index === null) {
      return this;
    }
    return this.changeValue(index, field, value);
  }

  /**
   * Prepend  without events
   * @param row
   * @param voidFormatter
   */
  doPrepend(row: KeyValueObject, voidFormatter?: boolean): this {
    row = {...row};
    row = (!voidFormatter ? this.formatRow(row) : row);
    this.items.unshift(new CollectionItem(row));
    return this;
  }

  prepend(row: KeyValueObject, voidFormatter: boolean = false): this {
    this.doPrepend(row, voidFormatter);
    this.fireEvent(FunctionEventTypeEnum.afterSet, this.items[0], 0);
    this.isItemsDirty = true;
    return this;
  }

  /**
   * Add without events
   * @param row
   * @param voidFormatter
   */
  doAdd(row: KeyValueObject, voidFormatter?: boolean): this {
    row = {...row};
    row = (!voidFormatter ? this.formatRow(row) : row);
    this.items.push(new CollectionItem(row));
    return this;
  }

  add(row: KeyValueObject, voidFormatter: boolean = false): this {
    this.doAdd(row, voidFormatter);
    const count = this.items.length;
    const lastIndex = count - 1;
    this.fireEvent(FunctionEventTypeEnum.afterSet, this.items[lastIndex], lastIndex);
    this.isItemsDirty = true;
    return this;
  }

  autoModify(rows: KeyValueObject[], prepend?: boolean): this {
    rows.forEach(row => {
      if (this.getByID(row.ID) === null) {
        if (prepend) {
          this.doPrepend(row);
        }
        else {
          this.doAdd(row);
        }
      }
      else {
        this.setByID(row.ID, row, true);
      }
    });
    this.fireEvent();
    this.isItemsDirty = true;
    return this;
  }

  remove(index: number): this {
    this.items.splice(index, 1);
    this.fireEvent(FunctionEventTypeEnum.afterDelete);
    this.isItemsDirty = true;
    return this;
  }

  removeByField(field: string, value: any): this {
    const index = this.getIndexByField(field, value);
    if (index === null) {
      return this;
    }
    return this.remove(index);
  }

  removeByID(ID: number): this {
    const index = this.getIndexByField('ID', toNumber(ID));
    if (index === null) {
      return this;
    }
    return this.remove(index);
  }

  set(index: number, row: KeyValueObject, voidEvents: boolean = false): this {
    if (!this.exists(index)) {
      throw new Error('index does not exist');
    }
    const newRow = this.formatRow(row);
    this.isItemsDirty = this.at(index).replace(newRow as KeyValueObject).isDirty();
    if (!voidEvents) {
      this.fireEvent(FunctionEventTypeEnum.afterSet, {value: newRow, index: index});
    }
    return this;
  }

  mergeRow(index: number, row: KeyValueObject, voidEvents: boolean = false): this {
    if (!this.exists(index)) {
      throw new Error('index does not exist');
    }
    return this.set(index, {...this.at(index).getItem(), ...row}, voidEvents);
  }

  setByFieldValue(field: string, value: any, row: KeyValueObject, voidEvents: boolean = false): this {
    const index = this.getIndexByField(field, value, null);
    if (index === null) {
      return this;
    }
    return this.set(index, row, voidEvents);
  }

  setByField(field: string, row: KeyValueObject, voidEvents: boolean = false): this {
    return this.setByFieldValue(field, row[field], row, voidEvents);
  }

  setByID(ID: number, row: KeyValueObject, voidEvents: boolean = false): this {
    return this.setByFieldValue('ID', toNumber(ID), row, voidEvents);
  }

  reOrderByField(field: string, activeValue: any, overValue: any): this {
    const oldIndex = this.getIndexByField(field, activeValue);
    const newIndex = this.getIndexByField(field, overValue);
    if (oldIndex === null || newIndex === null) {
      return this;
    }
    this.items = [...arrayMove(this.items, oldIndex, newIndex)];
    this.fireEvent(FunctionEventTypeEnum.afterOrder, this.getIDS());
    return this;
  }

  //endregion

  //region getters
  at(index: number): CollectionItem {
    return this.items[index];
  }

  atID(ID: number): CollectionItem {
    const found = this.items.find(item => item.get('ID') === ID);
    if (found === undefined) {
      return new CollectionItem({});
    }
    return found;
  }

  get(index: number, defaultValue: any = null): KeyValueObject {
    if (!this.exists(index)) {
      return defaultValue;
    }
    return this.items[index].getItem();
  }

  getValueStrLength(index: number, field: string): number {
    if (!this.exists(index, field)) {
      return 0;
    }
    return this.getValue(index, field, '')?.length ?? 0;
  }

  getValue(index: number, field: string, defaultValue: any = null): any {
    if (!this.exists(index)) {
      return defaultValue ?? null;
    }
    if (!this.at(index).exists(field)) {
      return defaultValue;
    }
    return this.at(index).get(field);
  }

  getArray(index: number, field: string): any[] {
    return this.getValue(index, field, []);
  }

  first(defaultValue: any = null): KeyValueObject {
    return this.get(0, defaultValue);
  }

  last(defaultValue: any = null): KeyValueObject {
    return this.get(this.items.length - 1, defaultValue);
  }

  findByFieldValue(field: string, value: any, defaultValue: any = null): KeyValueObject | any {
    const found = this.items.find((item) => item.exists(field) && item.get(field) === value);
    if (!found) {
      return defaultValue ?? null;
    }
    return found.getItem();
  }

  findValue<S extends (row: KeyValueObject, index?: number) => boolean, F extends string, A = null>(callback: S, field: F, defaults: A = (null as unknown) as A): any {
    const found = this.find(callback, null);
    return found ? found[field] : defaults;
  }

  getByID(ID: number, defaultValue: any = null): KeyValueObject | any {
    return this.findByFieldValue('ID', ID, defaultValue);
  }

  getIndexByField(field: string, value: any, defaultValue: any = null): number | null {
    const index = this.items.findIndex((item) => item.get(field) === value);
    if (index < 0) {
      return defaultValue ?? null;
    }
    return index;
  }

  getIndexByID(ID: number, defaultValue: any = null): number | null {
    return this.getIndexByField('ID', ID, defaultValue);
  }

  getIDS(): number[] {
    return this.getFieldValues('ID');
  }

  getSortIDS(): string[] {
    return this.map(item => item.ID.toString());
  }

  getDirty(): KeyValueObject[] {
    return [...this.items].filter(item => item.isDirty()).map(item => item.getItem());
  }

  getItems(): KeyValueObject[] {
    return [...this.items].map(item => item.getItem());
  }

  count(): number {
    return this.items.length;
  }

  logConsole(): void {
    console.log(this.getItems());
  }

  //endregion

  //region formatters

  private formatRow(row: KeyValueObject): KeyValueObject {
    return this.itemFormatter.fire(row);
  }

  //endregion

  //region events
  fireEvent(event?: FunctionEventType, ...data: any): this {
    if (event) {
      this.events.fire(event, ...data);
    }
    this.events.fire(FunctionEventTypeEnum.afterEdit);
    return this;
  }

  handleOrderEndByID(event: DragEndEvent): void {
    const active = event.active as Active;
    const over = event.over as Over;
    if (active.id !== over.id) {
      this.reOrderByField('ID', toNumber(active.id), toNumber(over.id));
    }
  }

  afterSet(caller: FunctionEventCallback): void {
    this.events.add(FunctionEventTypeEnum.afterSet, caller);
  }

  afterEdit(caller: EmptyEventCallback): void {
    this.events.add(FunctionEventTypeEnum.afterEdit, caller);
  }

  afterChangeValue(caller: AfterChangeValueCallBack): void {
    this.events.add(FunctionEventTypeEnum.afterChangeValue, caller);
  }

  afterOrder(caller: AfterOrderEventCallback): void {
    this.events.add(FunctionEventTypeEnum.afterOrder, caller);
  }

  after(events: FunctionEventType, caller: FunctionEventCallback): void {
    this.events.add(events, caller);
  }


  //endregion

  //region array extenders
  countBy(callback: (row: KeyValueObject, index: number) => any): number {
    return this.getItems().filter((item, index) => callback(item, index)).length;
  }

  distinct(callback: (row: KeyValueObject, index: number) => any): any[] {
    const items = [] as any[];
    this.each((item, index) => {
      const row = item.getItem();
      const value = callback(row, index);
      if (!items.includes(value)) {
        items.push(value);
      }
    });
    return items;
  }

  groupBy(getKey: (row: KeyValueObject, index: number) => string | number): KeyValueValueObject {
    const grouped = {};
    this.each((item, index) => {
      const row = item.getItem();
      const key = getKey(row, index).toString();
      // @ts-ignore
      if (typeof grouped[key] == 'undefined') {
        // @ts-ignore
        grouped[key] = [];
      }
      // @ts-ignore
      grouped[key].push(row);

    });
    return grouped;
  }

  map(callback: (row: KeyValueObject, index: number) => any): any {
    return this.getItems().map((item, index) => callback(item, index));
  }

  reMap(callback: (row: KeyValueObject, index: number,) => any, silent: boolean = false): this {
    const newItems = this.map(callback);
    if (silent) {
      this.setItems(newItems);
    }
    else {
      this.replace(newItems);
    }
    return this;
  }

  getFieldValues(field: string, filter?: (row: KeyValueObject, index: number) => boolean): any[] {
    const items = filter ? this.filter(filter) : this.getItems();
    return items.map(item => item[field]);
  }

  some(callback: (row: CollectionItem, index: number) => boolean): boolean {
    return this.items.some((item, index) => callback(item, index));
  }

  find<S extends (row: KeyValueObject, index?: number) => boolean, A = null>(callback: S, defaults: A = (null as unknown) as A): KeyValueObject | A {
    const found = this.getItems().find((item, index) => callback(item, index));
    if (found === undefined) {
      return defaults;
    }
    return found;
  }

  findLast<S extends (row: KeyValueObject, index?: number) => boolean, A = null>(callback: S, defaults: A = (null as unknown) as A): KeyValueObject | A {
    const found = this.getItems().reverse().find((item, index) => callback(item, index));
    if (found === undefined) {
      return defaults;
    }
    return found;
  }

  each(callback: (row: CollectionItem, index: number) => void): void {
    this.items.forEach(callback);
  }

  filter(callback: (row: KeyValueObject, index: number) => boolean): KeyValueObject[] {
    return this.getItems().filter((item, index) => callback(item, index));
  }


  //endregion

  //region states
  getState(): KeyValueObject[] {
    return this.getItems();
  }

  updateState(value: KeyValueObject[]): any {
    this.replace(value);
    return value;
  }

  //endregion

  //region HTML events
  createOnSelect(index: number = 0, field?: string): (e: OptionItem, inputName?: string) => void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const me = this;
    return (e: OptionItem, inputName?: string): void => {
      if (Array.isArray(e)) {
        const values = [] as (string | number)[];
        e.forEach(ee => {
          values.push(ee.value);
        });
        me.changeValue(index, inputName ?? field ?? '', values);
      }
      else {
        me.changeValue(index, inputName ?? field ?? '', e.value);
      }
    };
  }

  createOnChange(index: number = 0, field?: string): (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const me = this;
    return (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
      me.changeValue(index, field ?? e.target.name, e.target.value);
    };
  }

  createImageChange(index: number, fields: string | string[]): (image: ImageType | string) => void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const me = this;
    return (image: ImageType | string): void => {
      const mFields = (Array.isArray(fields) ? fields : [fields]);
      mFields.forEach(field => {
        if (typeof image === 'string') {
          me.changeValue(index, field, image);
        }
        else {
          me.changeValue(index, field, image.dataURL);
        }
      });
    };
  }

  //endregion
}
