import * as React from 'react';
import {AIImage, APIFile, KeyBoolObject, KeyValueObject, QueryParams} from 'services';
import {APIActionServiceContract} from 'services/api/APICollection_FormContracts';
import Config from 'services/Config';
import {collectFlatten} from 'utils/arrayExtendors';
import {APICollection, APICollectionProps} from 'utils/Collection';
import {errorModal} from 'utils/errorModal';
import {APIFormActions, FormValidator} from 'utils/Form';
import {APIFormContract} from 'utils/Form/APIFormContracts';
import {FunctionEventTypeEnum} from 'utils/FunctionEvents';
import {ObjectFormatterPropsType} from 'utils/ObjectFormatter';
import StateManager, {State} from 'utils/StateManager';
import {FunctionValidator} from 'utils/Validator';

interface APIFormCollectionStateValue {
  edited: boolean;
  hasErrors: boolean;
  errors: KeyBoolObject[];
}

interface APIFromCollectionState extends State {
  0: APIFormCollectionStateValue;
  1: React.Dispatch<React.SetStateAction<APIFormCollectionStateValue>>;
}

type afterSave = (response: KeyValueObject[], form: APIFormCollection) => void;

export interface APIFormCollectionProps extends APICollectionProps {
  emptyRow?: KeyValueObject;

  //validators?: ItemValidator[] | ItemValidator;
  generalValidator?: (form: APIFormCollection) => boolean;
  saveFormatter?: ObjectFormatterPropsType;
  api?: APIActionServiceContract;

  mapEmptyRowFieldsOnSave?: boolean; //defaults to true
  useState?: (stateValue: any) => State;
  formState?: APIFromCollectionState;

  afterSave?: afterSave;
}

//TODO items should be APIForm
export class APIFormCollection extends APICollection implements APIFormContract {

  protected emptyRow?: KeyValueObject;
  //protected validator: Validator;
  public api!: APIActionServiceContract;
  private action: APIFormActions;
  private state?: StateManager;
  private validator: FormValidator;
  protected generalValidator: (form: APIFormCollection) => boolean;

  constructor(props: APIFormCollectionProps) {
    let stateEmptyRow = {...props.emptyRow};
    if (props.rowFormatter && !Array.isArray(props.rowFormatter)) {
      stateEmptyRow = props.rowFormatter(stateEmptyRow);
    }
    if (props.useState) {
      props.state = props.useState([stateEmptyRow] as KeyValueObject[]);
      props.formState = props.useState(APIFormCollection.createState());
    }
    if (props.mapEmptyRowFieldsOnSave === undefined || props?.mapEmptyRowFieldsOnSave === true) {
      props.saveFormatter = collectFlatten([props.saveFormatter, (row: KeyValueObject): KeyValueObject => {
        const newRecord = {} as KeyValueObject;
        const er = {...props.emptyRow};
        Object.keys(er).forEach(field => {
          newRecord[field] = row[field] === undefined ? er[field] : row[field];
        });
        return newRecord;
      }]);
    }
    super(props);


    if (props.emptyRow) {
      this.emptyRow = {...props.emptyRow};
    }
    if (!props.api) {
      throw new Error('api is undefined');
    }
    this.api = props.api;
    this.action = new APIFormActions({
      form: this,
      api: props.api,
      saveFormatter: props.saveFormatter,
      events: props
    });
    this.afterEdit(() => {
      this.setEditedState(true);
    });
    this.validator = new FormValidator();
    if (props.generalValidator) {
      this.generalValidator = props.generalValidator;
    }
    else {
      this.generalValidator = () => {
        return true;
      };
    }
    if (props.formState) {
      this.state = new StateManager(props.formState);
    }
  }

  addSaveFormatter(formatter: ObjectFormatterPropsType) {
    this.action.addSaveFormatter(formatter);
  }

  setForm(record: number | any) {
    if (typeof record === 'number') {
      this.api.getRecord(record).then(response => {
        this.reset(response);
      });
    }
    else {
      this.reset(record);
    }
  }

  loadList(queryParams?: QueryParams): Promise<KeyValueObject[]> {
    return super.load(queryParams);
  }

  async save(queryParams?: QueryParams) {
    return await this.doSave(false, [this.get(0)], queryParams);
  }

  async saveMany(queryParams?: QueryParams) {
    return await this.doSave(true, this.getItems(), queryParams);
  }

  private async doSave(many: boolean, payload: KeyValueObject[], queryParams?: QueryParams) {
    if (this.generalValidator) {
      if (!this.generalValidator(this)) {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        return new Promise(() => {

        });
      }
    }
    this.setEditedState(true, false);
    const errors = [] as KeyValueObject[];
    let row: KeyValueObject;
    this.each((item, index) => {
      row = item.getItem();
      if (!errors[index]) {
        errors[index] = {};
      }
      for (const field in row) {
        errors[index][field] = !this.validate(index, field, row[field]);
      }
    });
    this.setErrors(errors);
    if (this.hasError()) {
      errorModal('Vigased väljad');
      if (Config.isLocal()) {
        console.error('errors', errors);
      }
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return new Promise(() => {

      });
    }
    return this.action.save(many, payload, queryParams);
  }

  isEdit(): boolean {
    return this.exists(0) ? this.get(0).ID > 0 : false;
  }

  reset(overWriteEmptyRow?: KeyValueObject): this {
    if (!this.emptyRow) {
      throw new Error('empty row is not defined');
    }
    const emptyRow = overWriteEmptyRow ? Object.assign(this.emptyRow, overWriteEmptyRow) : this.emptyRow;
    this.setItems([emptyRow]);
    this.setEditedState(false, true);
    this.refreshState();
    return this;
  }

  addEmpty() {
    if (!this.emptyRow) {
      throw new Error('empty row is not defined');
    }
    this.doAdd({...this.emptyRow}, true);
    this.setEditedState(false, true);
    this.refreshState();
  }

  isEmpty(): boolean {
    return this.some(item => item.isEmpty());
  }

  //region validation
  addValidator(field: string, validator: RegExp | FunctionValidator) {
    this.validator.add(field, validator);
  }

  /**
   * Returns true when valid
   * @param index
   * @param field
   * @param value
   */
  private validate(index: number, field: string, value?: any): boolean {
    if (!this.hasEdited()) {
      return true;
    }
    value = value === undefined ? this.get(index, field) : value;
    return this.validator.isValid(field, value);
  }

  hasError(): boolean {
    const errors = this.getErrors();
    for (const index in errors) {
      for (const field in errors[index]) {
        if (errors[index][field]) {
          return true;
        }
      }
    }
    return false;
  }

  fieldHasErrors(index: number, field: string) {
    const errors = this.getErrors();
    if (!errors[index]) {
      return false;
    }
    const errorRow = errors[index] as KeyBoolObject;
    if (!errorRow[field]) {
      return false;
    }
    return errorRow[field];
  }

  createValidator(index: number, field: string, validator: RegExp | FunctionValidator): FunctionValidator {
    if (validator) {
      this.addValidator(field, validator);
    }
    return (value: any): boolean => this.validate(index, field, value);
  }

  //endregion

  //region form state
  static createState(): APIFormCollectionStateValue {
    return {
      edited: false,
      hasErrors: false,
      errors: []
    };
  }

  setEditedState(setAsEdited: boolean = true, triggerUpdate: boolean = true): void {
    this.patchFormState({edited: setAsEdited}, triggerUpdate);
  }

  getFormState(): APIFormCollectionStateValue {
    // @ts-ignore
    return this.state.get();
  }

  patchFormState(overwrite: KeyValueObject, triggerUpdate: boolean = true) {
    if (!this.state) {
      return;
    }
    const newState = {...Object.assign(this.getFormState(), overwrite)};
    if (triggerUpdate) {
      this.state.update(newState);
    }
    else {
      this.state.setValue(newState);
    }
  }

  private getErrors(overwrite?: KeyBoolObject[]): KeyBoolObject[] {
    if (!this.state) {
      return [];
    }
    const errors = this.getFormState().errors;
    if (!overwrite) {
      return errors;
    }
    return Object.assign(errors, overwrite);
  }

  private setErrors(errors: KeyBoolObject[]): KeyBoolObject[] {
    this.patchFormState({errors: errors});
    return errors;
  }

  hasEdited(): boolean {
    if (!this.state) {
      return false;
    }
    return this.state.get().edited;
  }

  //endregion

  //region events
  afterSave(caller: afterSave): void {
    this.action.events.add(FunctionEventTypeEnum.afterSave, caller);
  }

  //endregion

  getSingleFile(index: number = 0, pictureField: string, uploadField: string): string | APIFile {
    if (this.exists(index, uploadField)) {
      return this.getValue(index, uploadField);
    }
    return this.getValue(index, pictureField, '');
  }

  getUploadPicture(index: number = 0, pictureField: string = 'picture', uploadField = 'picture64File'): string | AIImage {
    if (this.exists(index, uploadField)) {
      return this.getValue(index, uploadField);
    }
    return this.getValue(index, pictureField, '');
  }

  getPictures(index: number = 0, field: string = 'pictures'): AIImage[] {
    return this.getArray(index, field);
  }

  setPictures(index: number = 0, pictures: AIImage[], field: string = 'pictures'): this {
    return this.changeValue(index, field, pictures);
  }

  addPictures(index: number = 0, pictures: AIImage[], field: string = 'pictures'): this {
    return this.changeValue(index, field, [...this.getPictures(index)].concat([...pictures]));
  }

}