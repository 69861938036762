import * as React from 'react';
import {ImageType} from 'react-images-uploading/dist/typings';
import {AIImage, KeyBoolObject, KeyValueObject, QueryParams} from 'services';
import {APIFormActionsAPIContract} from 'services/api/APICollection_FormContracts';
import Config from 'services/Config';
import {OptionItem} from 'shared/components/Select';
import {CollectionItem, CollectionItemProps} from 'utils/Collection';
import {errorModal} from 'utils/errorModal';
import {APIFormActions, FormValidator} from 'utils/Form';
import {APIFormContract} from 'utils/Form/APIFormContracts';
import {Is} from 'utils/Is';
import {ObjectFormatter, ObjectFormatterPropsType} from 'utils/ObjectFormatter';
import StateManager, {State} from 'utils/StateManager';
import {FunctionValidator} from 'utils/Validator';


interface APIFromStateValue {
  data: KeyValueObject,
  edited: boolean;
  hasErrors: boolean;
  errors: KeyBoolObject;
}

interface APIFromState extends State {
  0: APIFromStateValue;
  1: React.Dispatch<React.SetStateAction<APIFromStateValue>>;
}

export interface APIFormProps extends CollectionItemProps {
  generalValidator?: (form: APIForm) => boolean;
  //data: KeyValueObject;
  state: APIFromState;
  resetValues?: KeyValueObject; //used in reset form
  api: APIFormActionsAPIContract;

  saveFormatter?: ObjectFormatterPropsType;
  loadFormatter?: ObjectFormatterPropsType; //will be used in replace item

  //events
  afterSave?: (response: KeyValueObject, form: APIForm) => void;
}


export class APIForm extends CollectionItem implements APIFormContract {

  private action: APIFormActions;
  protected validator: FormValidator;
  protected generalValidator: (form: APIForm) => boolean;
  private state: StateManager;
  private loadFormatter = new ObjectFormatter();

  private readonly resetValues!: KeyValueObject;

  constructor(props: APIFormProps) {
    super(props.state[0].data, props);
    this.action = new APIFormActions({
      form: this,
      api: props.api,
      saveFormatter: props.saveFormatter,
      events: props
    });
    this.resetValues = props.resetValues ? {...props.resetValues} : {};
    this.state = new StateManager(props.state);

    if (props.generalValidator) {
      this.generalValidator = props.generalValidator;
    }
    else {
      this.generalValidator = () => {
        return true;
      };
    }
    this.validator = new FormValidator();
    this.afterEdit(() => {
      this.updateStateData({...this.getItem()});
    });
    this.loadFormatter.add(props.loadFormatter);

  }

  addSaveFormatter(formatter: ObjectFormatterPropsType) {
    this.action.addSaveFormatter(formatter);
  }

  async load(queryParams?: QueryParams): Promise<any> {
    return await this.action.load(queryParams).then(response => {
      this.fill(this.loadFormatter.fire(response));
    });
  }


  async save(queryParams?: QueryParams): Promise<any> {
    return await this.doSave(() => {
      return this.action.save(false, [this.getItem()], queryParams);
    });
  }

  async saveMany(queryParams?: QueryParams): Promise<any> {
    return await this.doSave(() => {
      return this.action.save(true, [this.getItem()], queryParams);
    });
  }

  async doSave(saveAction: () => Promise<any>): Promise<any> {
    if (this.generalValidator) {
      if (!this.generalValidator(this)) {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        return new Promise(() => {

        });
      }
    }
    this.setEditedState(true, false);
    const errors = this.getErrors();
    this.each((value, field) => {
      errors[field] = !this.validate(field);
    });
    this.setErrors(errors);
    if (this.hasError()) {
      errorModal('Vigased väljad');
      if (Config.isLocal()) {
        console.error('errors', errors);
      }
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return new Promise(() => {

      });
    }
    return saveAction();
  }

  onChange(eventOrValue: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | string | number, index: number, field: string): void {
    // @ts-ignore
    const value = Is.object(eventOrValue) ? eventOrValue.target.value : eventOrValue;
    this.change(field, value);
  }

  onChangeTarget(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void {
    // @ts-ignore
    this.change(e.target.name, e.target.value);
  }

  change(field: string, value: any) {
    this.set(field, value);
    this.setError(field, this.validate(field));
  }

  fill(item: KeyValueObject): this {
    return this.replace(item);
  }

  fillKeys(item: KeyValueObject): this {
    Object.keys(item).forEach(field => {
      this.set(field, item[field], true);
    });
    this.refreshState();
    return this;
  }

  reset(): this {
    if (this.resetValues) {
      this.replace(this.resetValues, true);
    }
    this.updateStateData({...this.getItem()}, false);
    return this;
  }

  isEdit(): boolean {
    return this.isEmpty() ? false : this.get('ID') > 0;
  }

  getUploadPicture(pictureField: string = 'picture', uploadField = 'picture64File'): string | AIImage {
    if (this.exists(uploadField)) {
      return this.get(uploadField);
    }
    return this.get(pictureField, '');

  }

  //region HTML events
  createOnSelect(field?: string): (e: OptionItem, inputName?: string) => void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const me = this;
    return (e: OptionItem, inputName?: string): void => {
      if (Array.isArray(e)) {
        const values = [] as (string | number)[];
        e.forEach(ee => {
          values.push(ee.value);
        });
        me.change(inputName ?? field ?? '', values);
      }
      else {
        me.change(inputName ?? field ?? '', e.value);
      }
    };
  }

  createOnChange(field?: string): (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const me = this;
    return (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
      me.change(field ?? event.target.name, event.target.value);
    };
  }

  createImageChange(fields: string | string[]): (image: ImageType | string) => void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const me = this;
    return (image: ImageType | string): void => {
      const mFields = (Array.isArray(fields) ? fields : [fields]);
      mFields.forEach(field => {
        if (typeof image === 'string') {
          me.change(field, image);
        }
        else {
          me.change(field, image.dataURL);
        }
      });
    };
  }

  createOnToggle(field?: string): (event: React.ChangeEvent<HTMLInputElement>, state: boolean) => void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const me = this;
    return (event: React.ChangeEvent<HTMLInputElement>, state: boolean): void => {
      me.change(field ?? event.target.name, !state);
    };
  }

  createSaveClick(queryParams?: QueryParams): () => void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const me = this;
    return (): void => {
      me.save(queryParams);
    };
  }

  //endregion

  //region validation
  addValidator(field: string, validator: RegExp | FunctionValidator) {
    this.validator.add(field, validator);
  }

  setError(field: string, state: boolean) {
    const fieldState = {} as KeyBoolObject;
    fieldState[field] = state;
    this.patchState({errors: this.getErrors(fieldState)});
  }

  /**
   * Returns true when valid
   * @param field
   * @param value
   */
  private validate(field: string, value?: any): boolean {
    if (!this.hasEdited()) {
      return true;
    }

    value = value === undefined ? this.get(field) : value;
    return this.validator.isValid(field, value);
  }

  createValidator(field: string, validator?: RegExp | FunctionValidator): FunctionValidator {
    if (validator) {
      this.addValidator(field, validator);
    }
    return (value: any): boolean => this.validate(field, value);
  }

  /**
   * @param field if field is not defined all form is checked
   */
  hasError(field?: string): boolean {
    const errors = this.getErrors();
    if (field) {
      const index = Object.keys(errors).indexOf(field);
      if (index < 0) {
        return false;
      }
      return errors[index];
    }
    for (const ef in errors) {
      if (errors[ef]) {
        return true;
      }
    }
    return false;
  }

  hasEdited(): boolean {
    return this.state.get().edited;
  }

  //endregion

  //region state
  static createState(data: KeyValueObject): APIFromStateValue {
    return {
      data: data,
      edited: false,
      hasErrors: false,
      errors: {} as KeyBoolObject
    };
  }

  patchState(overwrite: KeyValueObject, triggerUpdate: boolean = true): APIFromStateValue {
    return this.updateState(Object.assign(this.getState(), overwrite), triggerUpdate);
  }

  updateState(newState: APIFromStateValue, triggerUpdate: boolean = true): APIFromStateValue {
    if (triggerUpdate) {
      this.state.update({...newState});
    }
    else {
      this.state.setValue({...newState});
    }
    return newState;
  }

  updateStateData(data: KeyValueObject, setAsEdited: boolean = true): void {
    this.updateState(Object.assign(this.getState(), {edited: setAsEdited, data: data}));
  }

  setEditedState(setAsEdited: boolean = true, triggerUpdate: boolean = true): void {
    this.patchState({edited: setAsEdited}, triggerUpdate);
  }

  getState(): APIFromStateValue {
    return this.state.get();
  }

  refreshState() {
    this.updateState(this.getState());
  }

  private getErrors(overwrite?: KeyBoolObject): KeyBoolObject {
    const errors = this.getState().errors;
    if (!overwrite) {
      return errors;
    }
    return Object.assign(errors, overwrite);
  }

  private setErrors(errors: KeyBoolObject): KeyBoolObject {
    this.patchState({errors: errors});
    return errors;
  }


  //endregion

}