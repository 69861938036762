import {AIImage, KeyValueObject} from 'services';
import {FunctionEventCallback, FunctionEvents, FunctionEventTypeEnum, KeyValueObjectEvenCallback, ValueFieldEventCallback} from 'utils/FunctionEvents';
import {Is} from 'utils/Is';
import {ObjectFormatter, ObjectFormatterPropsType} from 'utils/ObjectFormatter';

export interface CollectionItemProps {

  formatter?: ObjectFormatterPropsType; //will be used in replace item
  //events
  afterEdit?: KeyValueObjectEvenCallback;
  afterSet?: ValueFieldEventCallback;
  dirtyChange?: FunctionEventCallback;
}

//https://blog.simontest.net/extend-array-with-typescript-965cc1134b3 worth to checkout
export class CollectionItem {
  private item: KeyValueObject;
  private isItemDirty: boolean;
  protected events!: FunctionEvents;
  private formatter = new ObjectFormatter();


  constructor(item: KeyValueObject, props?: CollectionItemProps) {
    this.formatter.add(props?.formatter);
    this.item = item;
    this.isItemDirty = false;
    this.events = new FunctionEvents(props);
  }

  of(field: string): CollectionItem {
    return new CollectionItem(this.get(field, {}));
  }

  exists(field: string): boolean {
    if (this.item === null) {
      return false;
    }
    return Object.keys(this.item).indexOf(field) >= 0;
  }

  //region dirty
  dirty(): boolean {
    return this.isItemDirty;
  }

  private setIsDirty(is: boolean) {
    this.isItemDirty = is;
    this.events.fire(FunctionEventTypeEnum.dirtyChange, is);
  }

  isDirty(): boolean {
    return this.isItemDirty;
  }

  //endregion

  replace(item: KeyValueObject, silent: boolean = false): this {
    this.setIsDirty(!Is.equal(this.item, item));
    this.item = this.formatter.fire(item);
    if (!silent) {
      this.events.fire(FunctionEventTypeEnum.afterEdit, item);
    }
    return this;
  }

  set(field: string, value: any, silent: boolean = false): this {
    const currentValue = this.get(field, null);
    this.setIsDirty(!Is.equal(currentValue, value));
    this.item[field] = value;
    if (!silent) {
      this.events.fire(FunctionEventTypeEnum.afterEdit, this.item);
      this.events.fire(FunctionEventTypeEnum.afterSet, value, field);
    }
    return this;
  }

  setPictures(pictures: AIImage[], field: string = 'pictures'): this {
    return this.set(field, pictures);
  }

  addPictures(pictures: AIImage[], field: string = 'pictures'): this {
    return this.set(field, [...this.getPictures(field)].concat([...pictures]));
  }

  get<F extends string, D = null>(field: F, defaults: D = (null as unknown) as D): any | D {
    if (!this.exists(field)) {
      return defaults;
    }
    return this.item[field];
  }

  getLength(field: string): number {
    if (!this.exists(field)) {
      return 0;
    }
    return this.item[field].length;
  }

  getArray(field: string): any[] {
    return this.get(field, []);
  }

  getPictures(field: string = 'pictures'): AIImage[] {
    return this.get(field, []);
  }

  getID(defaultValue: any = null): number {
    return this.get('ID', defaultValue);
  }

  getItem(): KeyValueObject {
    return this.item;
  }

  logConsole(): void {
    console.log(this.getItem());
  }


  isEmpty(field?: string): boolean {
    if (field) {
      if (!this.exists(field)) {
        return true;
      }
      return Is.empty(this.get(field), true);
    }
    return !Object.keys(this.item).some(f => !this.isEmpty(f));
  }

  is(field: string, checkValue: any): boolean {
    return this.get(field, null) === checkValue;
  }

  isOneOf(field: string, checkValue: any[]): boolean {
    return checkValue.includes(this.get(field, undefined));
  }

  isNotEmpty(field?: string): boolean {
    return !this.isEmpty(field);
  }

  each(callback: (value: any, field: string) => void) {
    for (const field in this.item) {
      callback(this.item[field], field);
    }
  }

  some(callback: (value: any, field: string) => boolean): boolean {
    for (const field in this.item) {
      if (callback(this.item[field], field)) {
        return true;
      }
    }
    return false;
  }

  filter(callback: (value: any, field: string) => boolean): any[] {
    const items = [];
    for (const field in this.item) {
      if (callback(this.item[field], field)) {
        items.push(this.item[field]);
      }
    }
    return items;
  }

  //region events

  afterSet(caller: ValueFieldEventCallback): void {
    this.events.add(FunctionEventTypeEnum.afterSet, caller);
  }

  dirtyChange(caller: FunctionEventCallback): void {
    this.events.add(FunctionEventTypeEnum.dirtyChange, caller);
  }

  afterEdit(caller: KeyValueObjectEvenCallback): void {
    this.events.add(FunctionEventTypeEnum.afterEdit, caller);
  }

  //endregion
}
