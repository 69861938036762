import { Homepage, HomepageAPIService, HomepageStatus } from 'services';

export class HomepageCommonService extends HomepageAPIService {

  saveDomainByID(homepageID: number, domain: string): Promise<Homepage> {
    return this.save({
      ID    : homepageID,
      domain: domain
    } as Homepage);
  }

  setStatusByID(homepageID: number, status: HomepageStatus): Promise<Homepage> {
    return this.save({
      ID    : homepageID,
      status: status
    } as Homepage);
  }

  setTemplateID(homepageID: number, templateID: number): Promise<Homepage> {
    return this.save({
      ID        : homepageID,
      templateID: templateID
    } as Homepage);
  }

  save(data: Homepage): Promise<Homepage> {
    return this.callPost('save', 'save', data);
  }

}