//import $                 from 'jquery'
import * as React        from 'react';
import { CSSProperties } from 'react';
import * as Styled       from './Modal.styled';

interface OwnProps {
  isOpen: boolean;
  onClose: () => void;
  className?: string;
  shouldCloseOnOverlayClick?: boolean;
  marginTop?: number;
  contentCSS?: CSSProperties;
  overlayCSS?: CSSProperties;
  showClose?: boolean;
  title?: string;
  width?: string | number;
}

const Modal: React.FC<OwnProps> = (props) => {
  const close = function () {
    props.onClose();
  };


  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const reactToCSS   = require('react-style-object-to-css');
  const realContentCSS = props.contentCSS ?? {} as CSSProperties;
  const realOverlayCSS = props.overlayCSS ?? {} as CSSProperties;

  // const scrollTop         = $(window).scrollTop() ?? 0;
  const defaultOverlayCSS = {
    zIndex: 900,
  } as CSSProperties;

  const defaultContentCSS = {
    zIndex   : 55,
    top      : (props.marginTop ?? 10) + 'vh',
    left     : '50%',
    transform: 'translate(-50%,0)',
  } as CSSProperties;

  const contentCSS = reactToCSS(Object.assign(defaultContentCSS, realContentCSS));
  const overlayCSS = reactToCSS(Object.assign(defaultOverlayCSS, realOverlayCSS));

  return (
    <Styled.ModalStyled
      isOpen={props.isOpen}
      onRequestClose={close}
      shouldCloseOnOverlayClick={props.shouldCloseOnOverlayClick}
      marginTop={props.marginTop}
      ariaHideApp={false}
      modalHeight={document.getElementById('root')?.clientHeight}
      contentCSS={contentCSS}
      overlayCSS={overlayCSS}
      // data={{
      //   omt: scrollTop
      // }}
    >
      <Styled.Wrapper style={{width: props.width ?? 'auto'}}>
        {props.showClose &&
          <Styled.CloseButton onClick={close}>
            <Styled.CloseModalIcon/>
          </Styled.CloseButton>
        }
        {props.title &&
          <Styled.Header>
            <Styled.Title>{props.title}</Styled.Title>
          </Styled.Header>
        }
        {props.children}
      </Styled.Wrapper>
    </Styled.ModalStyled>
  );
};

export default Modal;
