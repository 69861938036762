import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import PrivateRoute from 'modules/self-service/pages/Auth/components/PrivateRoute';
import {HomepageFeatureCode, TokenService, UserRole} from 'services';
import Config from 'services/Config';
import APIVisualInterceptor from 'shared/components/APIVisualInterceptor';
import ErrorModal from 'shared/components/ErrorModal';
import {gsm} from 'store/globalStateManager';
import {GlobalVars} from 'utils/GlobalVars';

//region self-service
//region ss auth
const authPages = {
    forgotPassword: React.lazy(() => import ('modules/self-service/pages/Auth/views/ForgotPassword')),
    generateUser: React.lazy(() => import ('modules/self-service/pages/Auth/views/GenerateUser')),
    restorePassword: React.lazy(() => import ('modules/self-service/pages/Auth/views/RestorePassword')),
    referral: React.lazy(() => import('components/Referral')),
    login: React.lazy(() => import ('modules/self-service/pages/Auth/views/Login')),
};
//endregion
const companyDatabasePages = {
    dashboard: React.lazy(() => import('modules/self-service/pages/company-database/Dashboard/Dashboard')),
    management: React.lazy(() => import ('modules/self-service/pages/company-database/DataBaseManagementPage')),
    editProfile: React.lazy(() => import ('modules/self-service/pages/company-database/EditProfilePage')),
    emblembs: React.lazy(() => import ('modules/self-service/pages/company-database/Emblems')),
    statistic: React.lazy(() => import ('modules/self-service/pages/company-database/Statistic')),
};
const websitePages = {
    manageWebSite: React.lazy(() => import ('modules/self-service/pages/homepage/ManageWebSitePage')),
    changeHomePage: React.lazy(() => import ('modules/self-service/pages/homepage/ChangeSitePage')),
    statistic: React.lazy(() => import ('modules/self-service/pages/homepage/StatisticsPage')),
    modules: React.lazy(() => import('modules/self-service/pages/homepage/Modules')),
};
const Settings = React.lazy(() => import('modules/self-service/pages/Settings/Settings'));
const FirstUpload = React.lazy(() => import('features/FirstUpload/FirstUpload'));
const WebsiteFeatureMasterPage = React.lazy(() => import ('modules/self-service/pages/homepage/WebsiteFeatureMasterPage'));
//endregion

//region crm
const MasterCRM = React.lazy(() => import('scenes/MasterCRM'));
const Tag = React.lazy(() => import('scenes/CRM/Tag'));
const Comments = React.lazy(() => import('scenes/CRM/Comments'));
const Domain = React.lazy(() => import('scenes/CRM/Domain'));
const DashboardCRM = React.lazy(() => import('scenes/CRM/Dashboard'));
const StatisticCRM = React.lazy(() => import('features/homepage/components/Statistic'));
const CRMLogin = React.lazy(() => import ('scenes/CRM/Login'));
//endregion

const App: React.FC = () => {

    const dispatch = useDispatch();
    const [isLoading, showLoading] = useState(true);
    const currentUserRole = Config.isCRM() ? UserRole.crm : UserRole.selfService;
    GlobalVars.setUserRole(currentUserRole);
    gsm.withDispatch(dispatch).withSelector(useSelector).createSelectors();

    useEffect(() => {
        if (isLoading) {
            const tokenService = new TokenService();
            if (currentUserRole === UserRole.crm) {
                if (TokenService.hasUserAttached()) {
                    showLoading(false);
                }
                else {
                    const queryParams = new URLSearchParams(window.location.search);
                    tokenService.getById(queryParams.get('tokenId') ?? '').then(token => {
                        TokenService.store(token);
                        showLoading(false);
                    });
                }
            }
            else {
                showLoading(false);
            }
        }
        // eslint-disable-next-line
    }, [dispatch]);

    if (isLoading) {
        return <div>Loading....</div>;
    }

    const replaceFavIconLocal = () => {
        Array.from(document.getElementsByTagName("link")).forEach(function (element) {
            if (element.href.toString().includes('favicon'))
                element.remove();
        });
        const fav = document.createElement('link');
        fav.rel = 'icon';
        fav.type = 'image/x-icon';
        if (Config.isSelfService()) {
            fav.href = '/favicon_local_ss.ico';
        }
        else {
            fav.href = '/favicon_local_crm.ico';
        }
        document.getElementsByTagName('head')[0].append(fav);
    };

    if (Config.isLocal()) {
        replaceFavIconLocal();
    }

    if (currentUserRole === UserRole.crm) {
        document.getElementsByTagName('title')[0].innerHTML = 'CRM';
        const pathRegex = /^\/company\/homepage\/(\d+)/gm.exec(window.location.pathname);
        if (!pathRegex && !['/', '/login'].includes(window.location.pathname)) {
            return <div>Ettevõtte ID on puudu</div>;
        }
        return (
            <React.Suspense fallback={<div>Loading...</div>}>
                <BrowserRouter>
                    <Switch>
                        <Route exact path={['/', '/login']} component={CRMLogin}/>
                        <PrivateRoute exact path={['/company/homepage/:CID', '/company/homepage/:CID/domain']} comp={() => <MasterCRM><Domain/></MasterCRM>}/>
                        <PrivateRoute path="/company/homepage/:CID/:CHID/tag" comp={() => <MasterCRM><Tag/></MasterCRM>}/>
                        <PrivateRoute path="/company/homepage/:CID/:CHID/comments" comp={() => <MasterCRM><Comments/></MasterCRM>}/>
                        <PrivateRoute path="/company/homepage/:CID/:CHID/stats" comp={() => <MasterCRM><StatisticCRM/></MasterCRM>}/>
                        <PrivateRoute path="/company/homepage/:CID/:CHID/dashboard" comp={() => <MasterCRM><DashboardCRM/></MasterCRM>}/>
                    </Switch>
                    <ErrorModal/>
                    <APIVisualInterceptor/>
                </BrowserRouter>
            </React.Suspense>
        );
    }
    //////////////////////////////////////////////// start of self service

    document.getElementsByTagName('title')[0].innerHTML = 'Hange.ee iseteenindus';
    return (
        <React.Suspense fallback={<div>Loading...</div>}>
            <BrowserRouter>
                <Switch>
                    <Route path="/forgotPassword" component={authPages.forgotPassword}/>
                    <Route path="/generateuser" component={authPages.generateUser}/>
                    <Route path="/restorePassword/:code" component={authPages.restorePassword}/>
                    <Route path={"/:refCode([a-f0-9]{32})"} component={authPages.referral}/>
                    <Route path={"/first-upload/:refCode([a-f0-9]{32})"} component={FirstUpload}/>
                    <Route exact path="/login" component={authPages.login}/>
                    <PrivateRoute path="/company/database" comp={companyDatabasePages.management}/>
                    <PrivateRoute path="/company/editprofile" comp={companyDatabasePages.editProfile}/>
                    <PrivateRoute path="/company/statistics" comp={companyDatabasePages.statistic}/>
                    <PrivateRoute path="/company/emblems" comp={companyDatabasePages.emblembs}/>
                    <PrivateRoute path="/website/:HID/statistic" comp={websitePages.statistic}/>
                    <PrivateRoute path="/website/:HID/managewebsite" comp={websitePages.manageWebSite}/>
                    <PrivateRoute path="/website/:HID/changehomepage" comp={websitePages.changeHomePage}/>
                    <PrivateRoute path="/website/:HID/modules" comp={websitePages.modules}/>

                    <PrivateRoute path="/website/:HID/work" comp={() => <WebsiteFeatureMasterPage feature={HomepageFeatureCode.work}/>}/>
                    <PrivateRoute path="/website/:HID/jobannouncements" comp={() => <WebsiteFeatureMasterPage feature={HomepageFeatureCode.job}/>}/>
                    <PrivateRoute path="/website/:HID/pricelist" comp={() => <WebsiteFeatureMasterPage feature={HomepageFeatureCode.priceList}/>}/>
                    <PrivateRoute path="/website/:HID/partners" comp={() => <WebsiteFeatureMasterPage feature={HomepageFeatureCode.partners}/>}/>
                    <PrivateRoute path="/website/:HID/certificates" comp={() => <WebsiteFeatureMasterPage feature={HomepageFeatureCode.certificates}/>}/>
                    <PrivateRoute path="/website/:HID/activity" comp={() => <WebsiteFeatureMasterPage feature={HomepageFeatureCode.services}/>}/>
                    <PrivateRoute path="/website/:HID/machines" comp={() => <WebsiteFeatureMasterPage feature={HomepageFeatureCode.machines}/>}/>
                    <PrivateRoute path="/website/:HID/products" comp={() => <WebsiteFeatureMasterPage feature={HomepageFeatureCode.products}/>}/>
                    <PrivateRoute path="/website/:HID/blog" comp={() => <WebsiteFeatureMasterPage feature={HomepageFeatureCode.blog}/>}/>

                    <PrivateRoute path="/settings" comp={Settings}/>
                    <PrivateRoute exact path={['/', '/dashboard']} comp={companyDatabasePages.dashboard}/>
                </Switch>

                <ErrorModal/>
                <APIVisualInterceptor/>
            </BrowserRouter>
        </React.Suspense>
    );
};

export default App;
