import {toNumber} from 'lodash';
import {KeyValueObject, UserRole} from 'services';

export class GlobalVars {
  
  static routeParams = {} as KeyValueObject;
  private static userRole: UserRole;
  
  static useParams(params: KeyValueObject) {
    GlobalVars.routeParams = params;
  }
  
  static getHomepageID(): number {
    const params = GlobalVars.routeParams;
    if (GlobalVars.isUserRoleCRM()) {
      return params.CHID ? toNumber(params.CHID) : 0;
    } else {
      return params.HID ? toNumber(params.HID) : 0;
    }
  }
  
  static getCompanyID(): number {
    const params = GlobalVars.routeParams;
    if (GlobalVars.isUserRoleCRM()) {
      return params.CID ? toNumber(params.CID) : 0;
    } else {
      return 9999;
    }
  }
  
  static setUserRole(role: UserRole) {
    GlobalVars.userRole = role;
  }
  
  
  static getUserRole(): UserRole {
    return GlobalVars.userRole;
  }
  
  static isUserRoleCRM(): boolean {
    return GlobalVars.userRole === UserRole.crm;
  }
  
  static isRoleSelfService(): boolean {
    return GlobalVars.userRole === UserRole.selfService;
  }
}

