import { CommonSuccessResponse, HomepageFeatureSettingsService } from 'services';
import { APICollection, APICollectionProps }                     from 'utils/Collection';

interface HomepageFeaturesListProps extends APICollectionProps {
  afterToggle?: () => void;
}

export class HomepageFeaturesList extends APICollection {
  public api: HomepageFeatureSettingsService;

  constructor(props: HomepageFeaturesListProps) {
    const api = new HomepageFeatureSettingsService();
    super({
      loadFormatter   : (row) => {
        row.savedFeature = {...row};
        row.selected     = false;
        return row;
      },
      afterChangeValue: (evetData) => {
        const {value, field, row} = evetData;
        if (field === 'active') {
          api.saveActiveStatus(row.ID, value).then(() => {
            if (props.afterToggle) {
              props.afterToggle();
            }
          });
        }
      },
      ...props
    });
    this.api = api;
  }

  getSelectedFeatureID(): number {
    return this.findValue(row => row.selected, 'ID', 0);
  }

  setSelectedFeature(featureID: number) {
    this.reMap(row => {
      row.selected = featureID === row.ID;
      return row;
    });
  }

  getSelectedFeatureTypeID(featureID: number) {
    return this.atID(featureID).get('selectedTypeID', 0);
  }

  setSelectedFeatureTypeID(featureID: number, featureTypeID: number) {
    const featureType = this.atID(featureID).getArray('types').find(type => type.ID === featureTypeID);
    this.mergeRow(this.getIndexByID(featureID) ?? -1, {
      'selectedTypeID'        : featureTypeID,
      'selectedTypeCode'      : featureType?.code ?? '',
      'selectedTypeDataListID': featureType?.dataListID ?? '',
    });
  }

  saveSelectedFeatureType(featureID: number): Promise<CommonSuccessResponse> {
    return this.api.saveSelectedFeatureType(featureID, this.getSelectedFeatureTypeID(featureID));
  }
}
