import { routerMiddleware }             from 'connected-react-router';
import { createBrowserHistory }         from 'history';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools }          from 'redux-devtools-extension';
import thunk                            from 'redux-thunk';
import rootReducer                      from './rootReducer';

// browser history
export const history = createBrowserHistory();

// compose enhancers
const enhancer = composeWithDevTools(
  applyMiddleware(routerMiddleware(history), thunk),
);

// rehydrate state on app start
const initialState = {};

// create store
const store = createStore(rootReducer(history), initialState, enhancer);

export type RootState = ReturnType<typeof store.getState>;
export default store;