import { commonLocalization } from 'shared/commonLocalization';
import { gsm }                from 'store/globalStateManager';

export const errorModal = (error: any, title?: any) => {
  console.log('error',error);
  if (typeof error === 'string' && typeof title === 'string') {
    gsm.error(error, title);
  }
  else if (typeof error === 'string') {
    gsm.error(error);
  }
  else if (error.response?.status === 500) {
    gsm.error(commonLocalization.serverError);
  }
  else if (error.response.data) {
    const data = error.response.data;
    if (data.modal) {
      gsm.error(data.modal.content, data.modal.title);
    }
    else if (data.error && !data.message) {
      gsm.error(data.error);
    }
    else if (!data.error && data.message) {
      gsm.error(data.message);
    }
    else {
      gsm.error(data.message, data.error);
    }
  }
  else {
    gsm.error();
  }
};
