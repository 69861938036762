import {APIService, CommonSuccessResponse, KeyValueObject, QueryParams} from 'services';
import {APIActionServiceContract} from 'services/api/APICollection_FormContracts';

export class CommonAPIActionService extends APIService implements APIActionServiceContract {

  getByID(ID: string | number, queryParams?: QueryParams): Promise<any> {
    return this.callGet('get', `${ID.toString()}`, queryParams);
  }

  reOrder(ids: number[], queryParams?: QueryParams): Promise<CommonSuccessResponse> {
    return this.callPatch('reOrder', 'reOrder', ids, queryParams);
  }

  getList(queryParams?: QueryParams): Promise<any> {
    return this.callGet('list', 'list', queryParams);
  }

  load(queryParams?: QueryParams): Promise<any> {
    return this.get(queryParams);
  }

  getRecord(ID: string | number): Promise<any> {
    return this.getByID(ID);
  }

  //###################

  save(payload: KeyValueObject, queryParams?: QueryParams): Promise<any> {
    return this.callPost('save', 'save', payload, queryParams);
  }

  saveMany(payload: KeyValueObject[], queryParams?: QueryParams): Promise<any> {
    return this.callPost('saveMany', 'save', payload, queryParams);
  }

  delete(ID: number, queryParams?: QueryParams): Promise<CommonSuccessResponse> {
    return this.callDelete('delete', `${ID}`, queryParams);
  }

  togglePublish(ID: number, published: boolean): Promise<any> {
    return this.callPatch('togglePublish', 'modify', [{ID: ID, patch: {published: published, savedOnly: false}}]);
  }

  publishAll(featureTypeID: number): Promise<any> {
    return this.callPost('publishAll', 'publishAll', {featureTypeID: featureTypeID});
  }
}