import {APIService, HomepageFeatureSettings, HomepageIDModuleAPIService, HomepageIDModuleAPIServiceProps, QueryParams, UserRole} from 'services';
import {APIActionServiceContract} from 'services/api/APICollection_FormContracts';

export interface HomepageFeatureAPIServiceProps extends HomepageIDModuleAPIServiceProps {
  feature?: HomepageFeatureSettings;
  role: UserRole;
}

export class HomepageFeatureAPIService extends HomepageIDModuleAPIService implements APIActionServiceContract {

  public feature: HomepageFeatureSettings;
  public savedFeature: HomepageFeatureSettings;
  public role: UserRole;

  constructor(props: HomepageFeatureAPIServiceProps) {
    super(props);
    if (!props.feature) {
      throw new Error('homepage feature is not defined');
    }
    this.role = props.role;
    this.feature = props.feature;
    this.savedFeature = props.feature.savedFeature;
    if (this.role === UserRole.crm) {
      this.addQueryParam('selectedFeatureTypeID', (this.feature.selectedTypeID ?? 0).toString());
    }
  }
}