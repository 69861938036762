import {toNumber} from 'lodash';
import {Homepage, HomepageFeatureCode, HomepageFeatureSettings, KeyValueObject, PublishedWorksByYear, UserRole} from 'services';
import {HomepageFeatureAPIService} from 'services';
import {APICollection} from 'utils/Collection';
import {APIFormCollectionProps, HomepageFeatureForm} from 'utils/Form';
import {FunctionEventCallback} from 'utils/FunctionEvents';
import {State} from 'utils/StateManager';


export interface HomepageFeatureServiceProps {
  role: UserRole;
  api?: HomepageFeatureAPIService;
  useState: (stateValue: any) => State;
  form: APIFormCollectionProps;
  feature: HomepageFeatureSettings;
  homepage: Homepage;

  //events
  afterLoad?: FunctionEventCallback;
}


export class HomepageFeatureService {

  public form: HomepageFeatureForm;
  public list: APICollection;
  public api: HomepageFeatureAPIService;
  private role: UserRole;
  private feature: HomepageFeatureSettings;

  constructor(props: HomepageFeatureServiceProps) {
    this.role = props.role;
    this.api = props.api ? props.api : new HomepageFeatureAPIService({
      role: props.role,
      module: props.feature.code,
      feature: props.feature,
    });
    this.list = new APICollection({
      api: this.api,
      state: props.useState([] as KeyValueObject[]),
    });
    this.form = new HomepageFeatureForm({
      ...props.form,
      homepage: props.homepage,
      emptyRow: HomepageFeatureService.getEmptyRow(props.feature),
      useState: props.useState,
      api: this.api,
      list: this.list
    });
    this.feature = props.feature;
  }

  isSavePublishOnly(): boolean {
    if (this.feature.code.toString() === HomepageFeatureCode.blog) {
      return true;
    }
    if (this.feature.selectedTypeCode === 'pdf') {
      return true;
    }
    if (this.role === UserRole.crm && this.form.isEdit() && this.form.isSavedOnly()) {
      return true;
    }
    return false;
  }

  effect() {
    this.form.reset();
    this.list.load();
  }

  getListItems(savedOnly: boolean): KeyValueObject[] {
    return this.list.filter(item => item.savedOnly === savedOnly);
  }

  getTotalPublished(savedOnly: boolean): number {
    return this.getListItems(savedOnly).filter(w => w.published).length;
  }

  getPublishedWorksByYear(savedOnly: boolean): PublishedWorksByYear[] {
    const items = this.getListItems(savedOnly);
    if (items.length <= 0) {
      return [];
    }

    const totalPublishedWorksByYearTmp = {} as KeyValueObject;
    items.forEach(work => {
      if (!totalPublishedWorksByYearTmp[work.year]) {
        totalPublishedWorksByYearTmp[work.year] = 0;
      }
      if (work.published) {
        totalPublishedWorksByYearTmp[work.year]++;
      }
    });
    const totalPublishedWorksByYear = [] as PublishedWorksByYear[];
    for (const year in totalPublishedWorksByYearTmp) {
      totalPublishedWorksByYear.push({
        year: toNumber(year),
        countPublishedWorks: totalPublishedWorksByYearTmp[year]
      });
    }

    return totalPublishedWorksByYear;
  }

  static getEmptyRow(feature: HomepageFeatureSettings): KeyValueObject {
    if (feature.selectedTypeCode === 'pdf') {
      return {
        ID: 0,
        title: '',
        pdf64File: null,
      };
    }
    if (feature.code === HomepageFeatureCode.certificates) {
      return {
        ID: 0,
        title: '',
        picture64File: null,
      };
    }
    if (feature.code === HomepageFeatureCode.services) {
      return {
        ID: 0,
        title: '',
        description: '',
        picture64File: null
      };
    }
    if (feature.code === HomepageFeatureCode.partners) {
      return {
        ID: 0,
        url: '',
        picture64File: null
      };
    }
    if (feature.code === HomepageFeatureCode.job) {
      return {
        ID: 0,
        title: '',
        description: ''
      };
    }
    if (feature.code === HomepageFeatureCode.priceList) {
      const emptyRow = {
        ID: 0,
        title: '',
        price: 0,
        unitID: 0
      } as KeyValueObject;
      if (feature.selectedTypeCode === 'category') {
        emptyRow.categoryID = 0;
      }
      return emptyRow;
    }
    if (feature.code === HomepageFeatureCode.blog) {
      return {
        ID: 0,
        selectedCategoryID: []
      };
    }
    if (feature.code === HomepageFeatureCode.work && feature.selectedTypeCode === 'pictures') {
      return {
        ID: 0,
        title: '',
        pictures: []
      };
    }
    if (feature.code === HomepageFeatureCode.work && feature.selectedTypeCode !== 'pictures') {
      return {
        ID: 0,
        title: '',
        year: 0,
        areaID: 0,
      };
    }
    if ([HomepageFeatureCode.machines.toString(), HomepageFeatureCode.products.toString()].includes(feature.code) && ['pictures', 'smallPictures'].includes(feature.selectedTypeCode)) {
      return {
        ID: 0,
        title: '',
        description: '',
        pictures: []
      };
    }
    if ([HomepageFeatureCode.machines.toString(), HomepageFeatureCode.products.toString()].includes(feature.code) && ['block', 'table'].includes(feature.selectedTypeCode)) {
      return {
        ID: 0,
        title: '',
        description: ''
      };
    }
    return {} as KeyValueObject;
  }

}