import { APIService, QueryParams, SelectValuesResponse } from 'services';

export class UiSelectValuesService extends APIService {

  private getSelectValues(type: string, queryParams?: QueryParams): Promise<SelectValuesResponse[]> {
    return super.callGet(type, `/ui/selectListValues/${type}`, queryParams);
  }

  public homepageJobYear(): Promise<SelectValuesResponse[]> {
    return this.getSelectValues('homepageJobYear');
  }

  public homepageTemplates(): Promise<SelectValuesResponse[]> {
    return this.getSelectValues('homepageTemplates');
  }

  public area(): Promise<SelectValuesResponse[]> {
    return this.getSelectValues('area');
  }

  public procurementBlogCategories(): Promise<SelectValuesResponse[]> {
    return this.getSelectValues('procurementBlogCategories');
  }

  public unit(): Promise<SelectValuesResponse[]> {
    return this.getSelectValues('unit');
  }

  public companyHomepages(ID: number): Promise<SelectValuesResponse[]> {
    return this.getSelectValues('companyHomepages', {ID: ID});
  }
}
