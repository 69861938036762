import {HomepageAPIService, HomepageFeatureCode, HomepageIDModulePropertyAPIService, QueryParams} from 'services';
import {GlobalVars} from 'utils/GlobalVars';

export interface HomepageIDModuleAPIServiceProps {
  module?: HomepageFeatureCode | string | string[];
  queryParams?: QueryParams;
}

export class HomepageIDModuleAPIService extends HomepageAPIService {

  private module: string = '';

  constructor(props: HomepageIDModuleAPIServiceProps) {
    const module = ((props.module ? (Array.isArray(props.module) ? props.module : [props.module.toString()]) : []) as string[]).join('/');
    super({
      path: `${GlobalVars.getHomepageID().toString()}/${module}`,
      queryParams: props.queryParams
    });
    this.module = module;
  }

  createCategoryPropertyService(): HomepageIDModulePropertyAPIService {
    return new HomepageIDModulePropertyAPIService(this.module, 'category');
  }

  createUnitPropertyService(): HomepageIDModulePropertyAPIService {
    return new HomepageIDModulePropertyAPIService(this.module, 'unit');
  }
}