import {AnonymousFunction, HomepageFeatureCode, KeyValueObject} from 'services';

export const commonLocalization = {


  //region choose
  chooseTheme: 'Vali kujundus',
  chooseReviews: 'Valin 3 hinnangut',
  choosePictureTitle: 'Vali seadmest pilt...',
  choosePdfTitle: 'Vali seadmest PDF',
  choosePdfTitleButton: 'Vali PDF',
  chooseVideoTitle: 'Vali seadmest video(.mp4,.ogg), FullHD(1920x1080), max(8MB) ',
  chooseButtonTitle: 'Vali pilt',
  chooseButtonVideoTitle: 'Vali video',
  choosePicture: 'Vali pilt',
  chooseTextFromBank: 'Vali sobiv tekst tekstipangast',
  chooseHomepageColors: 'Vali kodulehe värvid',
  chooseYear: 'Vali aasta',
  chooseArea: 'Vali piirkond',
  //endregion

  linkExpires: 'Link aegub',
  switchActive: 'Teenus aktiivne',
  switchInactive: 'Teenus mitteaktiivne',
  closeModule: 'Sulgen mooduli',
  cancel: 'Katkesta',
  lookExample: 'Vaata näidist',
  speedTitle: 'Töökiirus:',
  qualityTitle: 'Kvaliteet:',
  priceTitle: 'Hind:',
  confirmSelection: 'Tee valik',
  serverError: 'Server Error 500',
  unknownError: 'Tundmatu viga',
  errorTitle: 'Viga!',
  loadingOverlayTitle: 'Palun oota, laadin...',
  addItem: 'Lisa uus rida',
  addNewRow: 'Lisa uus rida',
  missingOptions: 'Valikud puudu',
  makeSelection: 'Tee valik ...',
  addNewOptions: 'Lisa uued valikud',
  businessClientSelfService: 'Ärikliendi iseteenindus',

  region: 'Piirkond',


  savedOnly: 'Salvestatud',
  saveAndPublish: 'Salvesta & Avalda',
  saveAndPublishHomepage: 'Salvesta & Avalda kodulehel',
  save: 'Salvesta',
  saveChange: 'Salvesta muutus',
  formResetButton: 'Tühista',
  formAddNewButton: 'Lisa uus',
  countPictures: 'fotot',
  viewProfile: 'Vaata avalikku profiili',
  viewOwnHomepage: 'Vaata oma kodulehte',

  addEditCategories: 'Lisa või muuda kategooriat',
  categories: 'Kategooriad',

  addEditUnits: 'Lisa või muuda ühikuid',
  units: 'Ühikud',

  workDescription: 'Töö kirjeldus',

  //region statistics
  homepageVisitStatistics: 'Kodulehe külastatavuse statistika',
  hangeProfileCompanydatabaseStatistics: 'PROFIIL HANGE.EE FIRMADE ANDMEBAASIS',
  //endregion

  //region login
  loginTitle: 'Logi sisse',
  createNewUserForCompany: 'Loo kasutaja ettevõttele',
  forgotTitle: 'Parooli taastamine',
  generateTitle: 'Loo kasutaja',
  emailPlaceholder: 'Sisesta e-maili aadress',
  passwordPlaceholder: 'Sisesta parool',
  confirmPasswordPlaceholder: 'Parool uuesti',
  rememberMe: 'Jäta mind meelde',
  forgotPassword: 'Unustasid parooli?',
  loginButton: 'Logi sisse',
  forgotButton: 'Saada uus parool',
  generateNewAccount: 'Loo uus kasutaja',
  restorePassword: 'Taasta parool',
  needSupport: 'Vajad abi sisselogimisel või sul puudub ligipääs?',
  required: 'field is required',
  emailError: 'emailError',
  passwordError: 'passwordError',
  confirmPasswordError: 'confirmPasswordError',
  //endregion

  //region CRM
  seo: 'SEO',
  contacts: 'Kontaktid',
  texts: 'Tekstid',
  enterText: 'Sisesta text',
  bigNumbersTitle: 'Suured numbrid',
  enterNewText: 'Kirjuta uus text',
  generalData: 'Üldandmed',
  designSelection: 'Kujunduse valimine',
  chooseColors: 'Värvid',
  mtr: 'MTR',
  //region general info
  choosePicturesFromGallery: 'Vali galeriist pilte',
  uploadLogo: 'Laadi üles logo',
  uploadPhoto: 'Laadi üles pilt',
  meisterExpertSign: 'Ekspert/meister märk',
  //endregion

  //region contacts
  companyName: 'Ettevõtte nimi',
  //endregion

  //endregion

  featureListTitle: (code: HomepageFeatureCode, savedOnly?: boolean) => {

    if (savedOnly) {
      return 'Salvestatud';
    }
    const titles = {} as KeyValueObject;
    titles[HomepageFeatureCode.ratings] = 'Hange.ee hinnangud';
    titles[HomepageFeatureCode.work] = 'Kodulehel avaldatud tehtud tööd';
    titles[HomepageFeatureCode.services] = 'Kodulehel avaldatud tegevusvaldkonnad';
    titles[HomepageFeatureCode.certificates] = 'Kodulehel avaldatud sertifikaadid';
    titles[HomepageFeatureCode.partners] = 'Kodulehel avaldatud partnerid';
    titles[HomepageFeatureCode.job] = 'Kodulehel avaldatud töökuulutused';
    titles[HomepageFeatureCode.priceList] = 'Kodulehel avaldatud hinnakiri';
    titles[HomepageFeatureCode.machines] = 'Kodulehel avaldatud tehnika';
    titles[HomepageFeatureCode.products] = 'Kodulehel avaldatud tooted';

    return titles[code] ?? '';
  },

  featureListSubTitle: (code: HomepageFeatureCode, publishedCount: number) => {

    const titles = {} as KeyValueObject;
    titles[HomepageFeatureCode.ratings] = 'Hinnangut';
    titles[HomepageFeatureCode.work] = 'Tehtud tööd';
    titles[HomepageFeatureCode.services] = 'Tegevusvaldkonda';
    titles[HomepageFeatureCode.certificates] = 'sertifikaati';
    titles[HomepageFeatureCode.partners] = 'partnerit';
    titles[HomepageFeatureCode.job] = 'töökuulutust';
    titles[HomepageFeatureCode.priceList] = 'kirjet';
    titles[HomepageFeatureCode.machines] = 'kirjet';
    titles[HomepageFeatureCode.products] = 'kirjet';

    const title = titles[code] ?? '';

    return `${publishedCount} ${title}`;
  },

  featureTitle: (code: HomepageFeatureCode) => {

    const titles = {} as KeyValueObject;
    titles[HomepageFeatureCode.ratings] = 'Hange.ee hinnangud';
    titles[HomepageFeatureCode.mtr] = 'MTR kanded';
    titles[HomepageFeatureCode.work] = 'Tehtud tööd';
    titles[HomepageFeatureCode.services] = 'Tegevusvaldkonnad';
    titles[HomepageFeatureCode.certificates] = 'Sertifikaadid';
    titles[HomepageFeatureCode.partners] = 'Koostööpartnerid';
    titles[HomepageFeatureCode.job] = 'Töökuulutused';
    titles[HomepageFeatureCode.priceList] = 'Hinnakiri';

    return titles[code] ?? '';
  },

  newFormTitle: (code: HomepageFeatureCode) => {

    const titles = {} as KeyValueObject;
    titles[HomepageFeatureCode.ratings] = '';
    titles[HomepageFeatureCode.mtr] = '';
    titles[HomepageFeatureCode.work] = 'Lisa uus tehtud töö';
    titles[HomepageFeatureCode.services] = 'Lisa tegevusvaldkonna ilmestav foto, nimetus ja kirjeldus';
    titles[HomepageFeatureCode.certificates] = 'Lisa sertifikaadi nimetus ja laadi üles foto.';
    titles[HomepageFeatureCode.partners] = 'Lisa koostööpartneri kodulehe link ja laadi üles logo';
    titles[HomepageFeatureCode.job] = 'Lisa uus kuulutus';
    titles[HomepageFeatureCode.priceList] = 'Siin saad lisada ja muuta ettevõtte hinnakirja.';
    titles[HomepageFeatureCode.machines] = 'Lisa masinat ilmestav foto, nimetus ja kirjeldus';
    titles[HomepageFeatureCode.products] = 'Lisa toodet ilmestav foto, nimetus ja kirjeldus';

    return titles[code] ?? '';
  },

  confirmDelete: (confirm: AnonymousFunction) => {
    if (window.confirm('Oled kindel, et soovid kustutadad')) {
      confirm();
    }
  }
};
