import { State } from 'utils/StateManager/types';

export class StateManager {
  protected state!: State;

  constructor(state: State | null) {
    if (state === null) {
      return;
    }
    this.state = state;
  }

  has(): boolean {
    return !!(this.state);
  }

  setValue(value: any): any {
    if (this.state) {
      this.state[0] = value;
    }
    return value;
  }

  update(value: any): any {
    if (this.state) {
      this.state[1](value);
    }
    return value;
  }

  refresh(): void {
    this.update(this.state[0]);
  }

  get(): any {
    return this.state[0];
  }
}

export default StateManager;