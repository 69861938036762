export interface QueryParamsObject {
  [key: string]: number | string;
}

export type QueryParams = QueryParamsObject | string;

export interface CommonSuccessResponse {
  success: boolean;
}

export enum UserRole {
  crm = 'crm',
  selfService = 'selfService'
}

export interface AIImage {
  ID: number;
  src: string;
  path: string;
  ai: string;
}

export interface APIFile {
  ID: number;
  src: string;
  path: string;
  extension: string; //file extension
}

export interface File64Type {
  dataURL?: string;
  file?: File;

  [key: string]: any;
}

export interface AnonymousFunction {
  (): any;
}

export interface KeyValueObject {
  [key: string]: any;
}

export interface KeyValueValueObject {
  [key: string]: KeyValueObject;
}

export interface KeyBoolObject {
  [key: string]: boolean;
}

export interface Token {
  tokenId: string;
  ts: string;
  created: string;
  jwt: string;
  userID: number | null;
  isExpired : boolean;
  role: UserRole;
}

export interface CreateLoginRequest {
  hash: string;
  accountID: number;
  email: string;
  password: string;
  passwordAgain: string;
}

export interface ChangePasswordRequest {
  newPassword: string;
  confirmPassword: string;
  oldPassword: string;
}

export interface SSCheckCompanyResponse {
  ID: number;
  active: boolean;
  companyID: number;
  email: string;
  companyName: string;
}

export interface RestorePasswordRequest {
  uuid: string;
  password: string;
  passwordAgain: string;
}

export enum CompanyServiceCode {
  cdb = 'cdb',
  homepage = 'homepage',
  offer = 'offer',
  hangeAccount = 'hangeAccount',
}

export interface Manager {
  ID: number;
  name: string;
  image: string;
  email: string;
  phone: string;
}

export interface PaymentMethod {
  type: string;
  code: string;
  displayName: string;
  countryCode: string;
  logoURL: string;
  paymentURL: string;
}

export interface CompanyFirstUploadStatus {
  hash: string,
  expireDate: string;
  expired: boolean
}

export interface CompanyResponse {
  ID: number;
  name: string;
  fullName: string;
  regNO: number;
  email: string;
  managerID: number;
  manager: Manager;
  publicProfile: string;
  firstUpload?: CompanyFirstUploadStatus;
}

export interface CompanyInvoicesResponse {
  ID: number;
  hash: string;
  no: string;
  insertDate: string;
  payedDate: string;
  sentDate: string;
  amountNet: number;
  amountGross: number;
  dueDate: string;
  status: string;
  contractID: number;
  managerID: number;
  companyID: number;
  offerID: number;
  isPayed: boolean;
  invoiceURL: string;
  paymentMethods: PaymentMethod[];
}

export interface CompanyService {
  ID: number;
  code: CompanyServiceCode;
  title: string;
  breif: string;
  description: string;
  button: string;
  label: string;
  labelValue: string;
  activeLabel: string;
  orderingTitle: string;
  active: boolean;
}

export interface OrderCompanyServiceRequest extends KeyValueObject {
  companyName: string;
  contactPersonName: string;
  email: string;
  phone: string;
  services: CompanyServiceCode[];
}

export interface EmblemsResponse {
  title: string;
  label: string;
  thumbnailURL: string;
  downloadUrl: string;
}

export interface TimePeriodsResponse {
  label: string;
  from: string;
  to: string;
}

export interface GeneralStatisticsResponse {
  code: string;
  label: string;
  tooltip: string;
  value: number;
}

interface GraphHighChartsDataItem {
  key: string;
  value: number;
}

export interface GraphHighChartsResponse {
  label: string;
  xAxis: string[];
  data: GraphHighChartsDataItem[];
}

export interface GraphLabelsResponse {
  code: string;
  label: string;
}

export interface SelectValuesResponse {
  value: number;
  defaultID: number;
  label: string;
}

export interface HomepageContacts {
  companyName: null | string;
  regNO: null | string;
  vatNO: null | string;
  address: null | string;
  email: null | string;
  phone: null | string;
  offerEmail: null | string;
  offerPhone: null | string;
  facebook: null | string;
  instagram: null | string;
  linkedIn: null | string;
  areaID: number[];
}

export interface HomepageContactPerson {
  ID: number;
  published: boolean;
  title: string | null;
  name: string | null;
  email: string | null;
  phone: string | null;
  picture: AIImage;
}

export interface HomepageFeaturePictureContract {
  firstPicture: AIImage;
  pictures: AIImage[];
}

export interface HomepageFeatureContract {
  ID: number;
  published: boolean;
  homepageID: number;
}

export interface HomepageTextConfig {
  code: string;
  maxChars: number;
  maxCharsRecommended: number;
  name: string;
}

export interface HomepageText {
  ID: number;
  text: string;
  config: HomepageTextConfig;
}

export interface HomepageTextBank {
  ID: number;
  text: string;
}

export interface HomepageScript {
  ID: number;
  name: string;
  content: string;
  code: string;
}

export interface HomepageComment {
  ID: number;
  creatorName: string;
  comment: string;
  ts: string;
}

export interface HomepageBigNumber {
  ID: number;
  number: string;
  description: string;
}

export interface PublishedWorksByYear {
  year: number;
  countPublishedWorks: number;
}

export interface Work extends HomepageFeatureContract, HomepageFeaturePictureContract {
  title: string;
  year: number;
  area: string;
  areaID: number;
}

export interface Machine extends HomepageFeatureContract, HomepageFeaturePictureContract {
  title: string;
  description: string;
}

export interface Product extends HomepageFeatureContract, HomepageFeaturePictureContract {
  title: string;
  description: string;
}


export interface HomepageFeatureSettingType {
  ID: number;
  dataListID: number;
  title: string;
  brief: string;
  example: {
    title: string;
    imageURL: string;
  };
  code: string;
  selected: boolean;
}

export interface HomepageDesignThemeColor {
  ID: number;
  name: string;
  label: string;
  value: string;
}

export interface HomepageDesignTheme {
  ID: number;
  name: string;
  label: string;
  isEditable: boolean;
  colors: HomepageDesignThemeColor[];
}

export enum HomepageFeatureCode {
  ratings = 'ratings',
  mtr = 'mtr',
  work = 'work',
  services = 'services',
  certificates = 'certificates',
  partners = 'partners',
  job = 'job',
  priceList = 'pricelist',

  //non API related modules
  modules = 'modules',
  manageWebSite = 'manageWebSite',
  changeHomePage = 'changeHomePage',
  statistics = 'statistics',
  generalInfo = 'generalInfo',
  contacts = 'contacts',
  contactPersons = 'contactPersons',
  machines = 'machines',
  products = 'products',
  blog = 'blog',
}


export interface HomepageFeatureSettings extends KeyValueObject {
  ID: number;
  code: HomepageFeatureCode;
  title: string;
  brief: string;
  actionTitle: string;
  description: string,
  selected: boolean; //is module opened UI
  active: boolean;
  selectedTypeID: number;
  selectedTypeCode: string;
  selectedTypeDataListID: number | null;
  types: HomepageFeatureSettingType[];
  savedFeature: HomepageFeatureSettings;
}

export interface Homepage {
  ID: number;
  hash: string;
  zoneVirtAccNO: number;
  isZoneServerAlias: boolean;
  domain: string;
  companyName: string;
  domainOwner: string;
  status: string;
  published: boolean;
  templateID: number;
  themeID: number;
  template: string;
  badge: boolean;
  mtr: boolean;
  mtrRecords: string[];
  seoTitle: string;
  seoDescription: string;
  usedSpace: number;
  usedSpaceGB: number;
  formattedUsedSpace: string;
}

export enum HomepageStatus {
  added = 'added',
  dnsRegistered = 'dnsRegistered',
  virtOrdered = 'virtOrdered',
  virtConnected = 'virtConnected',
}

export enum HomepageSaveFormOpt {
  savePublish = 'savePublish',
  saveOnly = 'saveOnly',
  savePublishAll = 'savePublishAll',
}

export interface CompanyRating {
  ID: number;
  date: string;
  title: string;
  url: string;
  rating: number;
  speed: number;
  quality: number;
  price: number;
  description: string;
  selected: boolean;
  isLastSelected: boolean;
}