import {KeyValueObject, QueryParams} from 'services';
import {APIFormActionsAPIContract} from 'services/api/APICollection_FormContracts';
import {APIForm, APIFormCollection} from 'utils/Form';
import {FunctionEvents, FunctionEventTypeEnum} from 'utils/FunctionEvents';
import {ObjectFormatter, ObjectFormatterPropsType} from 'utils/ObjectFormatter';

interface APIFormActionsProps {
  form: APIForm | APIFormCollection;
  api: APIFormActionsAPIContract;
  saveFormatter?: ObjectFormatterPropsType;
  events?: {
    afterSave?: (response: KeyValueObject | KeyValueObject[], form: APIForm | APIFormCollection) => void;
  };
}

export class APIFormActions {

  private api: APIFormActionsAPIContract;
  private form: APIForm | APIFormCollection;
  private saveFormatter = new ObjectFormatter();
  public events: FunctionEvents;

  constructor(props: APIFormActionsProps) {
    this.api = props.api;
    this.saveFormatter.add(props.saveFormatter);
    this.events = new FunctionEvents(props.events);
    this.form = props.form;
  }

  load(queryParams?: QueryParams): Promise<any> {
    if (!this.api.load) {
      throw new Error('API load method is not defiend');
    }
    return this.api.load(queryParams);
  }

  save(many: boolean, payload: KeyValueObject[], queryParams?: QueryParams): Promise<any> {
    const then = (response: any) => {
      this.events.fire(FunctionEventTypeEnum.afterSave, response, this.form);
      return response;
    };
    const realPayload = this.saveFormatter.rows(payload);
    if (many) {
      return this.api.saveMany(realPayload, queryParams).then(then);
    }
    return this.api.save(realPayload[0], queryParams).then(then);
  }

  addSaveFormatter(formatter: ObjectFormatterPropsType) {
    this.saveFormatter.add(formatter);
  }
}