import {HomepageAPIService, HomepageFeatureCode, HomepageIDModuleAPIService, QueryParams, SelectValuesResponse} from 'services';
import {GlobalVars} from 'utils/GlobalVars';
import StateManager from 'utils/StateManager';

export class HomepageIDModulePropertyAPIService extends HomepageIDModuleAPIService {

  constructor(module: HomepageFeatureCode | string, property: string, queryParams?: QueryParams) {
    super({
      module: `${module.toString()}/property/${property}`,
      queryParams: queryParams
    });
  }

  loadSelectValues(loadInto?: StateManager): Promise<SelectValuesResponse[]> {
    return super.callGet('loadModulePropertySelectValues', 'selectValues').then(values => {
      if (loadInto) {
        loadInto.update(values);
      }
      return values;
    });
  }
}