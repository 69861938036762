import {toNumber} from 'lodash';

export class Memory {

    static getEffect(): number {
        return toNumber(this.get('effectNumber'));
    }

    static effect() {
        if (!this.has('effectNumber')) {
            this.set('effectNumber', '1');
        }
        else {
            this.set('effectNumber', (this.getEffect() + 1).toString());
        }
    }

    static get(name: string): string|null {
        return window.localStorage.getItem(name);
    }

    static set(name: string, value: string): void {
        window.localStorage.setItem(name, value);
    }

    static has(name: string): boolean {
        return window.localStorage.getItem(name) !== null;
    }

    static remove(name: string) {
        window.localStorage.removeItem(name);
    }
}