import {noop} from 'lodash';
import * as React from 'react';

interface DangerousInnerHTMLProps {
  html: string;
  tagName?: string;
  className?: string;
  style?: any;
  onClick?: (event: React.MouseEvent) => void;
  nl2Br?: boolean;
}

const DangerousInnerHTML: React.FC<DangerousInnerHTMLProps> = ({html, tagName, className, style, onClick, nl2Br}) => {
  if (nl2Br) {
    html = html.toString().replaceAll(/\n/gm, '<br />');
  }
  return React.createElement(tagName || 'div', {
    dangerouslySetInnerHTML: {__html: html},
    className,
    style: style || {},
    onClick,
  });
};

DangerousInnerHTML.defaultProps = {
  tagName: 'div',
  className: '',
  onClick: noop,
};

export default DangerousInnerHTML;
