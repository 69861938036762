import {AxiosAPIDriver, Token, UserRole} from 'services';
import {Memory} from 'utils/Memory';
import {GlobalVars} from "../utils/GlobalVars";

export class TokenService extends AxiosAPIDriver {

    private SELF_SERVICE_API_KEY = 'SWqU2-uT48sJXH2ioMofu-vD09yTjdIMEcS-SrqJmYwI6KLYbK'; //selfService
    private CRM_API_KEY = 'b700a099-5079-417a-bc42-e6c2fd1570f3'; //crm

    async create(): Promise<Token> {
        const userRole = GlobalVars.getUserRole();
        const payload = {
            apiKey: userRole === UserRole.selfService ? this.SELF_SERVICE_API_KEY : this.CRM_API_KEY,
            deviceID: userRole === UserRole.selfService ? 'ss' : 'crm2'
        };
        return this.call('post', '/token/create', {}, payload).then(token => {
            return token as Token;
        });
    }

    async createAndStore(): Promise<Token> {
        return this.create().then(token => {
            TokenService.store(token);
            return token as Token;
        });
    }

    async reCreate(): Promise<Token> {
        const payload = {jwt: TokenService.getLocal().jwt};
        return this.call('post', '/token/reCreate', {}, payload).then(token => {
            return token as Token;
        });
    }

    async getById(tokenId: string): Promise<Token> {
        return this.call('get', `/token/${tokenId}`);
    }

    async get(): Promise<Token> {
        return new Promise(resolve => {
            if (TokenService.isStoredAndValid()) {
                resolve(TokenService.getLocal());
            }
            else {
                this.createAndStore().then(resolve);
            }
        });
    }

    async refreshLocalToken(): Promise<Token> {
        if (TokenService.isStoredAndValid()) {
            return this.getById(TokenService.getLocal().tokenId).then(token => {
                TokenService.store(token as Token);
                return token;
            });
        }
        else {
            return this.createAndStore();
        }
    }

    static hasUserAttached(): boolean {
        if (!TokenService.isStoredAndValid()) {
            return false;
        }
        const token = TokenService.getLocal();
        if (!token) {
            return false;
        }
        return token.userID !== null && token.userID > 0;
    }

    static store(token: Token): void {
        Memory.set('token', JSON.stringify(token));
    }

    static isStoredAndValid(): boolean {
        if (!Memory.has('token')) {
            return false;
        }
        return !TokenService.getLocal().isExpired;

    }

    static getLocal(): Token {
        if (!Memory.has('token')) {
            throw new Error('token is not stored');
        }
        // @ts-ignore
        return JSON.parse(Memory.get('token')) as Token;
    }

    static removeLocal(): void {
        Memory.remove('token');
    }
}