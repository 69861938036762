import { GeneralStatisticsResponse, GraphHighChartsResponse, GraphLabelsResponse, HomepageIDModuleAPIService, QueryParams, TimePeriodsResponse } from 'services';

export class HomepageStatisticsService extends HomepageIDModuleAPIService {

  constructor() {
    super({
      module: 'statistics'
    });
  }

  async getHomeStatistics(fromDate: string, toDate: string, queryParams?: QueryParams): Promise<GeneralStatisticsResponse[]> {
    return super.callPost('getHomeStatistics', `general`, {fromDate: fromDate, toDate: toDate}, queryParams);
  }

  async getHomeTimePeriods(queryParams?: QueryParams): Promise<TimePeriodsResponse[]> {
    return super.callGet('getHomeTimePeriods', `timePeriods`, queryParams);
  }

  async getHomeGraphLabels(queryParams?: QueryParams): Promise<GraphLabelsResponse[]> {
    return super.callGet('getHomeGraphLabels', `graph/labels`, queryParams);
  }

  async getHomeHighCharts(code: string, fromDate: string, toDate: string, queryParams?: QueryParams): Promise<GraphHighChartsResponse> {
    return super.callPost('getHomeHighCharts', `graph/highcharts/${code}`, { fromDate: fromDate, toDate: toDate }, queryParams);
  }
}