import {AxiosAPIDriver, KeyValueObject, QueryParams, QueryParamsObject} from 'services';
import {errorModal} from 'utils/errorModal';
import {AxiosRequestConfig} from "axios";

//TODO check this https://react-query.tanstack.com/ mmaybe this would be better solution
// https://www.youtube.com/watch?v=novnyCaa7To

enum CallEventType {
    before = 'before',
    after = 'after'
}

interface CallEvent {
    type: CallEventType;
    actionName: string|null;
    cb: (actionName: string|null, response?: any) => any;
    suspended: boolean;
}

export interface APIServiceProps {
    basePath?: string;
    queryParams?: QueryParams;
    config?: KeyValueObject;
}

export class APIService {

    private events: CallEvent[];
    private queryParams: QueryParamsObject;
    private basePath = '';
    private config?: KeyValueObject;

    constructor(props?: APIServiceProps) {
        this.events = [] as CallEvent[];
        this.queryParams = {} as QueryParamsObject;
        if (props) {
            if (props.basePath) {
                this.setBasePath(props.basePath);
            }
            this.queryParams = APIService.toQueryParamsObject(props.queryParams);
        }
        this.config = (props && props.config) ? props.config : {};
    }

    protected setBasePath(path: string): void {
        this.basePath = path;
    }

    protected getBasePath(): string {
        return this.basePath;
    }

    setAxiosConfig(config: KeyValueObject): this {
        this.config = config;
        return this;
    }

    getConfig(merge: KeyValueObject = {}): AxiosRequestConfig {
        return {...this.config, ...merge};
    }

    static createPath(base: string, path: string): string {

        if (path && path[0] === '/') {
            if (path.charAt(path.length - 1) === '/') {
                path = path.substring(0, path.length - 1);
            }
            return path;
        }

        if (base.charAt(base.length - 1) === '/') {
            base = base.substring(0, base.length - 1);
        }
        if (path && path[0] === '.') {
            return base + '/' + path.substring(1);
        }
        return base + (path ? '/' + path : '');
    }

    private createURI(endpoint: string, queryParams?: QueryParams): string {
        let uri = APIService.createPath(this.basePath, endpoint);
        if (uri.charAt(uri.length - 1) === '/') {
            uri = uri.substring(0, uri.length - 1);
        }

        uri += this.queryString(Object.assign(this.queryParams, APIService.toQueryParamsObject(queryParams)));
        return uri;
    }

    async callGet(actionName: string, endpoint: string, queryParams?: QueryParams): Promise<any> {
        return await this.call(actionName, 'get', endpoint, undefined, queryParams);
    }

    async callPut(actionName: string, endpoint: string, payload: any, queryParams?: QueryParams): Promise<any> {
        return await this.call(actionName, 'put', endpoint, payload, queryParams);
    }

    async callPost(actionName: string, endpoint: string, payload: any, queryParams?: QueryParams): Promise<any> {
        return await this.call(actionName, 'post', endpoint, payload, queryParams);
    }

    async callPatch(actionName: string, endpoint: string, payload: any, queryParams?: QueryParams): Promise<any> {
        return await this.call(actionName, 'patch', endpoint, payload, queryParams);
    }

    async callDelete(actionName: string, endpoint: string, queryParams?: QueryParams): Promise<any> {
        return await this.call(actionName, 'delete', endpoint, queryParams);
    }

    get(queryParams?: QueryParams): Promise<any> {
        return this.callGet('get', '', queryParams);
    }

    static toQueryParamsObject(params: string|QueryParamsObject|undefined): QueryParamsObject {
        let realParams = {} as QueryParamsObject;
        if (!params) {
            return realParams;
        }
        if (typeof params == 'string') {
            params.toString().split('&').forEach(p => {
                const sp = p.split('=');
                realParams[sp[0]] = sp[1];
            });

        }
        else {
            realParams = Object.assign(realParams, params);
        }
        return realParams;
    }

    private queryString(params: QueryParamsObject): string {
        if (Object.keys(params).length <= 0) {
            return '';
        }
        const urlParams = [] as string[];
        for (const i in params) {
            urlParams.push(`${i}=${params[i].toString()}`);
        }
        return '?' + urlParams.join('&');
    }

    addQueryParam(key: string, value: string) {
        this.queryParams[key] = value;
    }

    private async call(actionName: string, method: string, endpoint: string, payload?: any, queryParams?: QueryParams): Promise<any> {

        this.callEvents(CallEventType.before, actionName);
        return await new AxiosAPIDriver()
            .call(
                method,
                this.createURI(endpoint, queryParams),
                this.getConfig(),
                payload
            )
            .then((response: any) => {
                this.callEvents(CallEventType.after, actionName, response);
                return response;
            })
            .catch(error => {
                console.error('apiError', error.response);
                errorModal(error);
                return this;
            });
    }

    private callEvents(type: CallEventType, actionName: string|null, response?: any): this {
        this.events.map(event => {
            if (event.type === type && !event.suspended && (event.actionName === null || (event.actionName && event.actionName === actionName))) {
                event.cb(actionName, response);
            }
            event.suspended = false;
            return event;
        });
        return this;
    }

    private addEvent(type: CallEventType, actionName: string|null, callback: (response: any, actionName: string|null) => any): this {
        //do not add event twice
        if (this.events.find((event) => (event.type === type && event.actionName === actionName && event.cb.toString() === callback.toString()))) {
            return this;
        }
        this.events.push({
            type: type,
            actionName: actionName,
            cb: callback,
            suspended: false
        } as CallEvent);
        return this;
    }

    deleteEventByAction(actionName: string): this {
        this.events = this.events.filter((event) => event.actionName !== actionName);
        return this;
    }

    suspendBefore(): this {
        this.setEventSuspension(CallEventType.before, true);
        return this;
    }

    continueBefore(): this {
        this.setEventSuspension(CallEventType.before, false);
        return this;
    }

    suspendAfter(): this {
        this.setEventSuspension(CallEventType.after, true);
        return this;
    }

    continueAfter(): this {
        this.setEventSuspension(CallEventType.after, false);
        return this;
    }

    private setEventSuspension(type: CallEventType|null, suspended: boolean): this {
        this.events = this.events.map(event => {
            if (event.type === null || event.type === type) {
                event.suspended = suspended;
            }
            return event;
        });
        return this;
    }

    before(callBefore: (actionName: string) => any, actionName: string|null = null): void {
        this.addEvent(CallEventType.before, actionName, (actionName: string, response?: any) => callBefore(actionName));
    }

    after(callAfter: (response: any, actionName: string) => any, actionName: string|null = null): void {
        this.addEvent(CallEventType.after, actionName, (actionName: string, response?: any) => callAfter(response, actionName));
    }
}
