import * as React from 'react';
import {Redirect, Route, RouteProps} from 'react-router-dom';
import {TokenService} from 'services';
import {Is} from 'utils/Is';

interface compCompiler {
    (): JSX.Element;
}

interface OwnProps extends RouteProps {
    comp: React.LazyExoticComponent<React.FC> | compCompiler;
}

const PrivateRoute: React.FC<OwnProps> = (props) => {
    if (!TokenService.hasUserAttached()) {
        return (
            <Route
                {...props}
                render={({location}) => (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: {from: location}
                        }}
                    />
                )
                }
            />
        );
    }
    const realProps = {...props};
    // for (let i in props)
    // {
    //   let value = props[o]
    //   if (i == 'comp')
    //   {
    //
    //   }
    // }
    if (Is.func(realProps.comp)) {
        // @ts-ignore
        realProps.render = () => realProps.comp();
    }
    else {
        realProps.component = realProps.comp;
    }

    return (
        <Route {...realProps}/>
    );
};
export default PrivateRoute;
