import {AIImage, CommonAPIActionService, KeyValueObject, QueryParams} from 'services';
import {DateFormat} from 'utils/DateFormat';
import {APIForm} from 'utils/Form';
import {State} from 'utils/StateManager';


interface FirstUploadServiceProps {
  useState: (stateValue: any) => State;
  hash: string;
}

export class FirstUploadService {

  public form: APIForm;
  public api: CommonAPIActionService;

  constructor(props: FirstUploadServiceProps) {
    this.api = new CommonAPIActionService({
      basePath: `/crm/company/firstUpload/${props.hash}`
    });
    this.api.load = (queryParams?: QueryParams): Promise<any> => {
      return this.api.callGet('status', 'status');
    };
    this.form = new APIForm({
      state: props.useState(APIForm.createState({pictures: [], expired: false, expireDate: ''})),
      api: this.api,
      loadFormatter: (row: KeyValueObject) => {
        row.expireDate = row.expireDate ? DateFormat.toDMY(row.expireDate) : '';
        return row;
      },
      saveFormatter: (row: KeyValueObject) => {
        row.pictures = row.pictures.map((image: AIImage) => image.ID);
        return row;
      }
    });
  }

  getStatus(): Promise<any> {
    return this.api.callGet('status', 'status');
  }
}