import {KeyValueObject, OrderCompanyServiceRequest, QueryParams, SelfServiceCompanyService} from 'services';
import {APIFormActionsAPIContract} from 'services/api/APICollection_FormContracts';

export class CompanyOrderServiceService extends SelfServiceCompanyService implements APIFormActionsAPIContract {


  getRecord(ID: string | number): Promise<any> {
    throw new Error('not implemented');
  }

  save(payload: OrderCompanyServiceRequest, queryParams?: QueryParams): Promise<any> {
    return this.orderService(payload, queryParams);
  }

  saveMany(payload: KeyValueObject[], queryParams?: QueryParams): Promise<any> {
    throw new Error('not implemented');
  }

}