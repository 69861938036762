import moment, {MomentInput} from 'moment';

export class DateFormat {

  static toDMY(date: MomentInput): string {
    return DateFormat.format(date, 'DD.MM.YYYY');
  }

  static toDMYHM(date: MomentInput): string {
    return DateFormat.format(date, 'DD.MM.YYYY HH:MM');
  }

  static format(date: MomentInput | string, format: string): string {
    return moment(date).format(format);
  }

  static toDate = (date: MomentInput | string) => {
    if (!date) {
      return new Date();
    }
    return moment(date).toDate();
  };
}