import * as React from 'react';

const CloseModal: React.FC<JSX.IntrinsicElements['svg']> = () => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.8808 6.13864L6.13839 16.881C5.71461 17.3047 5.71461 17.9918 6.13839 18.4157C6.56217 18.8395 7.24925 18.8395 7.67301 18.4157L18.4154 7.67326C18.8392 7.24948 18.8392 6.5624 18.4154 6.13862C17.9917 5.71486 17.3046 5.71486 16.8808 6.13864Z" fill="#3C5168"/>
    <path d="M18.4148 16.881L7.67241 6.13863C7.24863 5.71486 6.56155 5.71486 6.13777 6.13863C5.71401 6.5624 5.71401 7.24948 6.13777 7.67326L16.8801 18.4156C17.3039 18.8394 17.9909 18.8394 18.4148 18.4156C18.8386 17.9919 18.8386 17.3048 18.4148 16.881Z" fill="#3C5168"/>
  </svg>
);

export default CloseModal;
