import {KeyValueObject} from 'services';
import {Is} from 'utils/Is';

export enum FunctionEventTypeEnum {
  afterChangeValue = 'afterChangeValue',
  afterSet = 'afterSet',
  afterDelete = 'afterDelete',
  afterEdit = 'afterEdit',
  afterOrder = 'afterOrder',
  afterSave = 'afterSave',
  afterUpdateState = 'afterUpdateState',
  afterLoad = 'afterLoad',
  dirtyChange = 'dirtyChange',
}

export interface FunctionEventCallback {
  (...data: any): any;
}

export interface KeyValueObjectEvenCallback {
  (item: KeyValueObject): any;
}

export interface ValueFieldEventCallback {
  (value: any, field: string): any;
}

export type FunctionEventType = FunctionEventTypeEnum | string | FunctionEventTypeEnum[] | string[];

export interface FunctionEventsProps {
  afterChangeValue?: FunctionEventCallback;
  afterSet?: FunctionEventCallback;
  afterDelete?: FunctionEventCallback;
  afterEdit?: FunctionEventCallback;
  afterOrder?: FunctionEventCallback;
  afterSave?: FunctionEventCallback;
  afterUpdateState?: FunctionEventCallback;
  afterLoad?: FunctionEventCallback;
}

export interface FunctionEventItem {
  type: FunctionEventTypeEnum;
  cb: FunctionEventCallback;
  suspended: boolean;
}

export class FunctionEvents {
  private events: FunctionEventItem[];
  
  constructor(events?: (KeyValueObject | undefined)[] | KeyValueObject) {
    this.events = [] as FunctionEventItem[];
    if (events) {
      // @ts-ignore
      this.addMultiple(Is.array(events) ? events : [events]);
    }
  }
  
  fire(eventType: FunctionEventType, ...data: any): this {
    const filterTypes = Is.array(eventType) ? eventType : [eventType];
    this.events.filter((event: FunctionEventItem) => filterTypes.includes(event.type)).forEach(event => event.cb(...data));
    return this;
  }
  
  add(events: FunctionEventType, callback: FunctionEventCallback): this {
    events = Array.isArray(events) ? events : [events];
    events.forEach(event => {
      this.events.push({
        type: event,
        cb: callback,
        suspended: false
      } as FunctionEventItem);
    });
    return this;
  }
  
  private addMultiple(events: KeyValueObject[]): this {
    const allEvents = Object.values(FunctionEventTypeEnum).map(item => item.toString());
    events.filter(item => item !== undefined && Is.object(item))
      .filter(event => Object.keys(event).some((name) => allEvents.includes(name)))
      .forEach((event: KeyValueObject) => {
        allEvents.forEach(eventName => {
          if (event[eventName]) {
            this.add(eventName, event[eventName]);
          }
        });
      });
    return this;
  }
  
  private setEventUsage(type: FunctionEventTypeEnum, suspended: boolean) {
    const filterTypes = [FunctionEventTypeEnum.afterEdit, type];
    this.events = this.events.map((event) => {
      if (filterTypes.includes(event.type)) {
        event.suspended = suspended;
      }
      return event;
    });
  }
}

