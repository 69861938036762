import { ConnectedRouter }             from 'connected-react-router';
import React                           from 'react';
import ReactDOM                        from 'react-dom';
import { Provider, ReactReduxContext } from 'react-redux';
import { ThemeProvider }               from 'styled-components';
import theme                           from 'utils/theme';
import App                             from './App';
import * as serviceWorker              from './serviceWorker';
import store, { history }              from './store';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store} context={ReactReduxContext}>
      <ConnectedRouter history={history} context={ReactReduxContext}>
        <ThemeProvider theme={theme}>
          <App/>
        </ThemeProvider>
      </ConnectedRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
