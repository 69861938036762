import * as React  from 'react';
import Modal       from 'shared/components/Modal';
import { gsm }     from 'store/globalStateManager';
import * as Styled from './ErrorModal.styled';

const ErrorModal: React.FC = () => {
  const error = gsm.getError();

  const closeModal = () => {
    gsm.closeError();
  };

  return (
    <Modal
      isOpen={error.state}
      onClose={closeModal}
      overlayCSS={{
        zIndex: 999999
      }}
    >
      <Styled.Wrapper>
        <Styled.CloseButton onClick={closeModal}>
          <Styled.CloseModalIcon/>
        </Styled.CloseButton>
        {error.title && <Styled.Title>{error.title}</Styled.Title>}
        {error.content && <Styled.Description html={error.content} />}
      </Styled.Wrapper>
    </Modal>
  );
};

export default ErrorModal;
