import {APIService, CommonAPIActionService, QueryParams} from 'services';
import {GlobalVars} from 'utils/GlobalVars';

interface HomepageAPIServiceProps {
  path: string;
  queryParams?: QueryParams;
}

export abstract class HomepageAPIService extends CommonAPIActionService {

  constructor(props?: HomepageAPIServiceProps) {
    if (!props) {
      props = {
        path: '',
        queryParams: {} as QueryParams
      };
    }
    super({
      basePath: APIService.createPath('/homepage/', props.path),
      queryParams: props.queryParams
    });
    if (GlobalVars.isUserRoleCRM()) {
      this.addQueryParam('CID', GlobalVars.getCompanyID().toString());
    }
  }
}