export interface FunctionValidator {
  (value: any, name?: string): boolean;  //return true when valid
}

export class Validator {
  static isValid(value: any, field: string, validator: RegExp | FunctionValidator): boolean {
    if (validator instanceof RegExp) {
      return (new RegExp(validator)).test(value);
    }
    return validator(value, field);
  }
  
  static hasError(value: any, field: string, validator?: RegExp | FunctionValidator): boolean {
    if (!validator) {
      return false;
    }
    return !Validator.isValid(value, field, validator);
  }
}