import { KeyValueObject }                                 from 'services';
import { Collection, CollectionOptions, CollectionState } from 'utils/Collection';
import { FunctionEventCallback, FunctionEventTypeEnum }   from 'utils/FunctionEvents';
import StateManager                                       from 'utils/StateManager';

export interface CollectionStateManagerProps extends CollectionOptions {
  state?: CollectionState;

  //events
  afterUpdateState?: FunctionEventCallback;
}


export class CollectionStateManager extends Collection {

  private manager: StateManager;

  constructor(props: CollectionStateManagerProps) {
    if (!props.state) {
      throw new Error('state is undefined');
    }
    super(Object.assign(props, {items: props.state[0]}));
    this.manager = new StateManager(props.state);
    this.afterEdit(() => {
      this.refreshState();
    });
  }

  getState(): KeyValueObject[] {
    return this.manager.get();
  }

  refreshState() {
    this.updateState([...this.getItems()]);
  }

  updateState(value: KeyValueObject[]): KeyValueObject[] {
    this.manager.update(value);
    this.events.fire(FunctionEventTypeEnum.afterUpdateState, value);
    return value;
  }

  afterUpdateState(caller: FunctionEventCallback): void {
    this.events.add(FunctionEventTypeEnum.afterUpdateState, caller);
  }
}
